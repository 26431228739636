<template>
  <div class="card-body">
    <div class="row">
      <div class="col-sm-6">
        <info_block v-bind:data="project.negative" v-bind:image_info="0" v-bind:status="`danger`"
                    v-bind:show_more="full_info_show" v-bind:country_code="active_country.code"
                    v-bind:project_id="project.id" v-bind:date="date"></info_block>
      </div>
      <div class="col-sm-6">
        <info_block v-bind:data="project.positive" v-bind:image_info="0" v-bind:status="`success`"
                    v-bind:show_more="full_info_show" v-bind:country_code="active_country.code"
                    v-bind:project_id="project.id" v-bind:date="date"></info_block>
      </div>
    </div>
    <div class="mt-4" v-if="funcAll">
      <button @click="funcAll()" class="btn btn-outline-dark">
        <template v-if="full_info_show">Скрыть</template>
        <template v-else>Показать</template>
        больше выборок
      </button>
    </div>

  </div>
</template>

<script>
import InfoBlock from "@/components/includes/InfoBlock.vue";

export default {
  name: "sermDetail",
  props: ['project', 'date', 'state', 'active_country', 'funcAll', 'full_info_show'],
  components: {
    'info_block': InfoBlock
  },
}
</script>

<style scoped>
.date {
  height: 100px;
  margin: 20px 0;
}

.card-body {
  padding: 15px;
}

</style>
