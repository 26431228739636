<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ">

      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">

        </div>
      </div>
    </div>

    <div class="card" v-if="Object.keys(snapshot).length > 0">
      <project_menu v-bind:id="$route.params.id" v-bind:route="$route.name" v-bind:project="project"></project_menu>
      <div class="card-header"  >
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col-sm">
            <h3>{{ snapshot.snapshot.name }}</h3>
          </li>

          <li class="d-flex flex-row mb-3 col-sm-6 flex-wrap justify-content-end">
            
            <router-link  
              v-if="$store.getters.user.is_superuser || ($store.getters.user.user_type == 'free')" :to="`/projects/${$route.params.id}/edit/`"
              type="button"
              class="mt-1 btn btn-sm btn-info ms-2 blue-light-bg"
              >Редактировать
            </router-link>

            <router-link 
              v-if=" $store.getters.user.user_type == 'offer'"  :to="`/projects/${$route.params.id}/forecast/`"
              type="button"
              class="mt-1 btn  btn-sm btn-info ms-2 blue-light-bg">План
            </router-link>

          </li>
        </ul>
      </div>

      <div class="card-body">
        <div class="row">
          <div class="ps-2 mb-3 col-sm flex">
            <!-- <span class="pe-2 text-muted">
              Проект в работе с <b class="text-dark" v-if="snapshot.snapshot.date_create">{{ snapshot.snapshot.date_create | moment("DD MMMM") }}</b>
            </span> -->
            
          <date-picker 
            v-model="active_date"
            class="date-picker" 
            style="display: inline-block; width: 150px; height: 31px;"
            readonly 
            ref="start_date" 
            :config="options">
          </date-picker>

          </div>


          <div class=" mb-3 col-sm justify-content-center d-flex">
            <CountryChoice
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="countries_options"
              v-bind:changeCountry="changeCountry">
            </CountryChoice> 
          </div>

           
          <div class="col text-right no-wrap"  v-if="$store.getters.user.is_superuser || ($store.getters.user.user_type == 'free')" >
            <router-link type="button" class="btn  btn-outline-primary ms-2 btn-sm" 
              :to="{ name: 'project_links', params: { id: $route.params.id }, query: {country_code: active_country.code, uniq: true}}">
                Уникальные ссылки
            </router-link>
          </div>
        </div>

        <detail_info 
          v-bind:project="snapshot.snapshot.country_data.find(x => x.code === this.active_country.code).data.project_stats.stats"
          v-bind:date="active_date"
          v-bind:funcAll="getFull"
          v-bind:state="state"
          v-bind:full_info_show="full_info_show"
          v-bind:active_country="active_country">
        </detail_info>

      </div>

    </div>
      
    <div class="row border-0 pb-1">
      <div class="col-sm justify-content-between justify-content-md-start d-flex  pt-1 flex-wrap">
        <button type="button" @click="state=10" :class="`btn me-1 btn-sm btn-outline-primary${state=='10'?' active':''}`">
          ТОП-10
        </button>
        <button type="button" @click="state=20" :class="`btn me-1 btn-sm btn-outline-primary${state=='20'?' active':''}`">
          ТОП-20
        </button>
        <button type="button" @click="state=50" :class="`btn me-1 btn-sm btn-outline-primary${state=='50'?' active':''}`">
          ТОП-50
        </button>
        <button type="button" @click="state=100" :class="`btn btn-sm btn-outline-primary${state=='100'?' active':''}`">
          ТОП-100
        </button>
        <div class="d-sm-flex ps-1" style="position:relative;">

          <date-picker 
            v-model="active_date"
            class="date-picker" 
            style="display: inline-block; width: 150px; height: 31px;"
            readonly 
            ref="start_date" 
            :config="options">
          </date-picker>

          <div class="form-check form-switch ms-3 pt-2 d-inline-block">
            <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
            <label class="form-check-label fz-9" for="full_url">Полные <br>ссылки</label>
          </div>

          <CountryChoice
            v-bind:active_country="active_country"
            v-bind:project_id="$route.params.id"
            v-bind:countries="countries_options"
            v-bind:changeCountry="changeCountry">
          </CountryChoice>

          <popper trigger="hover" :options="{ placement: 'top',modifiers: { offset: { offset: '0,10px' } } }">
            <span slot="reference">
              <i class="bi bi-question-circle"></i>
            </span>
            <template #content>
            <span class="popper text-start">
              При нажатой клавише Shift можно подсветить  одинаковые ссылки
            </span>
            </template>

          </popper>
        </div>
      </div>
    </div>

    <div class="card m-1 border-0 mb-3">

      <div class="card-body border border-primary">

        <div class="table-responsive d-flex">
          <table class="table" v-for="(key, index) in keywords">
            <thead>
            <tr>
              <th scope="col" class="text-center no-wrap" :colspan="!index?3:2">{{ key.name }}</th>
            </tr>
            </thead>

            <tbody>
              <tr class="text-center engine_info">
                <td v-if="!index" style="width: 50px;">
                </td>
                <td v-if="key.searches.yandex && key.searches.yandex.length>0">
                  <i class="yandex"></i>
                </td>
                <th v-if="key.searches.google && key.searches.google.length>0"><i class="google"></i></th>

              </tr>
              
              <tr>
                <td v-if="!index">
                  <table class="table abs">

                    <tbody>
                    <tr v-for="count in getCounter(key).slice(0,state)">
                      <td :class="`no-wrap${full_url?' full-url':''}`">
                        <div class="counter" v-if="!index">{{ count }}</div>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </td>
                <td v-if="key.searches.yandex && key.searches.yandex.length>0">
                  <table class="table">

                    <tbody>
                    <tr v-for="link in key.searches.yandex.slice(0,state)">
                      <td :class="`no-wrap link_info${full_url?' full-url text-truncate':''}`">

                        <span :class="`link`">
                          <a
                            :href="link.link"
                            :class=" bold_link==link.link_obj_id?' fw-bold link_display':''"
                            @click.prevent.exact="openInNewTab(link.link)"
                            @click.shift.exact="click_on_link_to_display(link.link_obj_id)"
                            :style="`${show_tone?getClass(link):''}`"
                            :ref="`link-${link.link_obj_id}`"
                            >{{ full_url ? link.link : link.domain }}
                          </a>
                        </span>

                      </td>

                    </tr>

                    </tbody>
                  </table>
                </td>
                <td v-if="key.searches.google && key.searches.google.length>0">
                  <table class="table">

                    <tbody>
                      <tr v-for="link in key.searches.google.slice(0,state)">
                        <td :class="`no-wrap link_info${full_url?' full-url text-truncate':''}`">
                          <span :class="`link`" :style="`${show_tone?getClass(link):''}`">
                              <a
                                :href="link.link"
                                :class=" bold_link==link.link_obj_id?' fw-bold link_display':''"
                                @click.prevent.exact="openInNewTab(link.link)"
                                @click.shift.exact="click_on_link_to_display(link.link_obj_id)"
                                :style="`${show_tone?getClass(link):''}`"
                                :ref="`link-${link.link_obj_id}`"
                                >{{ full_url ? link.link : link.domain }}
                              </a>

                          </span>
                        </td>

                      </tr>

                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

  </main>
</template>

<script>
  import CountryChoice from '@/components/CountryChoice.vue';
  import {ProjectApi} from '@/api/project'
  import moment from 'moment';
  import ProjectMenu from '@/components/ProjectMenu';
  import sermDetail from "@/views/project/Serm.vue";

  export default {
    name: 'ProjectSnapDetail',
    components: {
      CountryChoice,
      'project_menu': ProjectMenu,
      'detail_info': sermDetail
    },

    data() {
      return {
        show_non_check: false,
        bold_link: '',
        show_tone: true,
        info_by_date: false,
        active_country:{},
        project: {},
        old_active_date: null,
        active_date: null,
        keywords: [],
        state: 20,
        full_info: false,
        full_info_show: false,
        options: {
          format: 'DD/MM/YYYY',
          locale: 'ru',
          useCurrent: true,
          enabledDates: [],
          ignoreReadonly: true
        },
        full_url: false,
        link: null,
        snapshot : {},
        countries_options: [],
        country_options: [],
      }
    },
    computed: {
      date_query() {
        let date = moment(this.active_date, 'DD/MM/YYYY');
        return `${date.year()}-${date.month() + 1}-${date.date()}`;
      },
    },
    watch: {

      active_date: function (val) {
        let snapshot_date =  moment(this.snapshot.presentation_date).format('DD/MM/YYYY')
        if (snapshot_date != val) {
            this.get_snapshot(val)
        }
      },

      active_country(){
        this.keywords = this.snapshot.snapshot.country_data.find(x => x.code === this.active_country.code).data.top_links
      },
    },

    mounted: function () {
      if (this.$route.query.link) this.linkInfo(this.$route.query.link, false);
      this.active_date = moment().format('DD/MM/YYYY')
      this.get_snapshot(this.active_date)
      this.get_tr_state()

    },

    methods: {
      get_tr_state() {
        ProjectApi.get_tr_state(this.$route.params.id).then(response => {

          console.log('get_tr_state', response);
         this.state = response
        })
      },


      openInNewTab(url) {
        window.open(url, '_blank', 'noreferrer');
      },

      formated_date(date) {
          let formated_date = moment(date, 'DD/MM/YYYY');
          return `${formated_date.year()}-${formated_date.month() + 1}-${formated_date.date()}`;
        },

      get_snapshot(date) {
        ProjectApi.get_snapshot(this.$route.params.id, this.formated_date(date)).then(response => {
          this.snapshot = response
          this.active_country = response.snapshot.active_country_code
          this.active_date = moment(this.snapshot.presentation_date).format('DD/MM/YYYY');
          this.countries_options = response.project_tr_countries

          this.options.enabledDates = this.snapshot.all_snapshot_dates

          let country_data = JSON.parse(JSON.stringify(response.snapshot.country_data.find(x => x.code === this.active_country.code).data))
          this.project = country_data.project_stats.stats
        })
      },

      click_on_link_to_display(link_obj_id) {
        if (this.bold_link === link_obj_id) {
            this.bold_link = ''
        } else {
            this.bold_link = link_obj_id
        }
      },

      getClass(link) {
        if (link.error && !link.tone) return `color: #ff8c39`;
        else {
            let COLORS = this.$COLORS;
            let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
            return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
        }
      },

      changeCountry(country){
        this.active_country = country
      },

      getFull: function () {
            let $this = this;
            $this.full_info_show = !$this.full_info_show;
        },

      getCounter(key) {
        let count =0
        
        count = key.searches.yandex ? key.searches.yandex.length : 0;
        
        if (key.searches.google){
          if (key.searches.google.length >count){
            count =key.searches.google.length
          }
        }
        return Array.from({length: count}, (_, i) => i + 1)
        },

      closeLink: function () {
        history.pushState({}, null, `/cabinet${this.$route.path}`);
        this.link = null;
      },

      linkInfo: function (id, state = true, link = null) {
        this.link = id;
         if (link && !link.check) {
                link.check = true;
                this.project.no_check -= 1;
            }
        if (state) history.pushState({}, null, `/cabinet${this.$route.path}?link=${id}`);
      },
    }
  }

</script>

<style>
.date {
  height: 100px;
  margin: 20px 0;
}

.link {
  cursor: pointer;
}

td {
  max-width: 400px;
}

.table.abs {
  position: absolute;
  left: 20px;
  width: 50px;
}

.head {
  width: 105px;
  flex: 0 0 105px;
}

.place {
  width: 40px;
  text-align: left;
  margin-left: 0px;
  flex: 0 0 40px;
}

.card-body {
  padding: 15px;
}

.scroll-y {
  height: 250px;
  overflow-y: scroll;
}

.no-wrap.full-url {
  white-space: normal;
  height: 130px;
}

.card-header-tabs li a {
  white-space: nowrap;
  height: 30px;
}

.popper.text-start {
  max-width: 300px;
  white-space: normal;
}
</style>
<style scoped>
.engine_info {
  height: 50px !important;
}

.domain-max {
  max-width: 374px;
  overflow: hidden;
  display: block;
}

.text-right {
  text-align: right;
}

.link_info button.copy {
  display: none;
}

.link_info:hover button.copy {
  display: inline-block;
}

.link_info.full-url button.copy {
  display: inline-block;
}

.link_info button.copy {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.link_info {
  position: relative;
}

.link_display{
  background: rgba(13,110,253,.25);
}
</style>

