<template>
  <div class="btn-toolbar mb-2 mb-md-0 ">
    <div class="btn-group me-2">
      <button v-on:click="$router.go(-1)" class="btn btn-sm btn-primary position-fixed">
        <i class="bi bi-arrow-left-short me-1"></i>
        Назад
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackButton",
  props: ['link']
}
</script>

<style scoped>
  button {
    right: 30px;
    top: 70px;
    z-index: 10
  }
</style>
