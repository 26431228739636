<template>
  <div class="chat">
    <div class="header">
      Чат с менеджером
    </div>
    <div class="body" id="chat">
      <div class="chat-loader" v-if="load"></div>
      <template v-for="message in messages">
        <div :class="`message`+(message.user_data.id === $store.getters.user.id?' self':'')">

          <div class="msg-head">
            <span class="name">{{ message.user_data.fullname }}</span>
            <span class="date-time">{{ $moment(message.date_create).format("DD.MM.YYYY HH:mm") }}</span></div>
          <div class="msg">
            <blockquote v-if="message.blockquote">
              {{ message.blockquote }}
            </blockquote>
            <span v-html="message.text"></span>
          </div>

        </div>
        <div :class="`file-area`+(message.user_data.id === $store.getters.user.id?' self':'')" v-if="message.attachment">
          <i class="bi bi-file-earmark"></i>
          <a target="_blank" :href="message.attachment">
            {{ message.attachment.split("/")[message.attachment.split("/").length-1] }}</a>
        </div>
      </template>
    </div>
    <div class="footer">
      <form action="" @submit.prevent="addMessage()">

        <label for="chat_file" class="text-primary"><i class="bi bi-paperclip"></i></label>
        <input class="form-control"
               @change="addFile($refs.chat_file)"
               ref="chat_file" type="file"
               id="chat_file">
        <textarea v-model="new_message.text" required></textarea>
        <button class="btn btn-primary" type="submit"><i class="bi bi-send"></i></button>
      </form>
      <div class="attach-area" v-if="new_message.attachment">
        <span>Прикрепленный файл: </span>

        <div class="name">{{ new_message.attachment.name }}</div>
        <a href="" @click.prevent="new_message.attachment=null" class=" text-danger"><i class="bi bi-x"></i></a>
      </div>
    </div>
  </div>
</template>

<script>

import {ChatApi} from "@/components/chat/api";
import moment from "moment";

export default {
  name: 'Chat',
  props: {
    object_id: {type: Number},
    content_type: {type: Number},
    chat_id: {type: Function || null},
  },
  data() {
    return {
      chat: null,
      new_message: {},
      messages: [],
      chatSocket: null,
      page: 1,
      load: false
    }
  },
  mounted: function () {
    this.scrollBottom()
    let $this = this;
    ChatApi.get(this.object_id, this.content_type).then(r => {
      $this.chat = r
      $this.chat_id($this.chat.id)
      $this.connectChatWs()
    })
    const chat_area = document.getElementById('chat');
    chat_area.addEventListener(
        'scroll',
        function () {
          if (!chat_area.scrollTop) {
            if ($this.page) {
              $this.load = true;
              $this.getMessages(false)
            }
          }
        },
        false
    )

  },
  computed: {},
  watch: {
    chat() {
      this.getMessages();

    }

  },
  unmounted() {
    if (this.chatSocket) this.chatSocket.close();
  },
  methods: {
    addFile(ref, name) {
      this.new_message.attachment = ref.files[0]
    },
    getMessages(scroll = true) {
      const $this = this;
      ChatApi.list(this.chat.id, this.page).then(r => {
        let messages = r.results;
        messages.sort((a, b) => {
          return moment(a.date_create).diff(moment(b.date_create))
        })
        $this.messages = messages.concat(this.messages);
        $this.page += r.next ? 1 : (-$this.page);
        if (scroll) $this.scrollBottom()
        $this.load = false;
      })
    },
    connectChatWs() {
      const $this = this;
      const URL = `${location.protocol.includes("https") ? "wss" : "ws"}://${this.$NODE_ENV === "development" ? this.$VUE_APP_WS_URL : location.host}/ws/`
      this.chatSocket = new WebSocket(
          `${URL}chat/${this.chat.id}/`
      );

      this.chatSocket.onmessage = function (e) {

        let data = JSON.parse(JSON.parse(e.data))
        $this.messages.push(data);
        $this.scrollBottom()
      };

      this.chatSocket.onclose = function (e) {
        if ($this.chat.id) {
          setTimeout(function () {
            $this.connectChatWs();
          }, 1000);
        }
      };
      this.chatSocket.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        $this.chatSocket.close();
      };
    },

    addMessage() {

      if (this.new_message.attachment && this.new_message.attachment.size > 10*1024*1024){
         this.$notify({
            type: 'error',
            text: `Максимальный размер файла 10Мб`
          });
      }
      else{
          let formData = new FormData();
      for (let i in this.new_message) {
        formData.set(i, this.new_message[i])
      }
        ChatApi.add_message(this.chat.id, formData).then(() => this.new_message = {})
      }
    },
    scrollBottom() {
      const objDiv = document.getElementById("chat");
      setTimeout(() => objDiv.scrollTop = objDiv.scrollHeight, 300)

    }
  },

}

</script>
<style lang="scss" scoped>
$blue: rgba(135, 206, 250, 1);
$gray: rgba(154, 154, 154, 1);
$gray-light: rgb(240, 240, 240);
$black: rgba(18, 18, 18, 1);
$white: rgba(255, 255, 255, 1);

.chat-loader {
  width: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  height: 12px;
  display: block;
  flex: 0 0 auto;
  align-items: center;
  align-self: center;
  animation: l5 1s infinite linear alternate;
}

@keyframes l5 {
  0% {
    box-shadow: 20px 0 $black, -20px 0 $gray;
    background: #000
  }
  33% {
    box-shadow: 20px 0 $black, -20px 0 $gray;
    background: $gray
  }
  66% {
    box-shadow: 20px 0 $gray, -20px 0 $black;
    background: #0002
  }
  100% {
    box-shadow: 20px 0 $gray, -20px 0 $black;
    background: $black
  }
}


.chat {
  width: 100%;
  min-width: 360px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 0;
  background: $gray-light;
  position: sticky;
  top: 0;

  .header {
    background: $blue;
    padding: 10px;
    color: $white;
  }

  .body {
    border-radius: 5px;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 60vh;
    border: 1px solid $gray-light;
    margin: 0 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    scrollbar-width: thin;
    scrollbar-color: $black $gray-light;

    .file-area {
      max-width: 350px;
      font-size: 12px;
      display: flex;
      border: 1px solid;
      border-radius: 15px;
      padding: 10px;
      &.self{
        margin-left: auto;
      }

      a {
        color: $black;
        text-decoration: underline;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 6px;
        margin: 0;
      }

      i {
        font-size: 30px;
      }
    }

    .message {
      width: 70%;
      border: 1px solid $blue;
      border-radius: 15px;
      padding: 10px;
      background: $blue;
      position: relative;
      max-width: 350px;


      .msg-head {
        display: flex;
        justify-content: space-between;

        .name {
          font-weight: bold;
          font-size: 12px;
        }

        .date-time {
          font-size: 11px;
        }
      }

      .msg {
        font-size: 13px;

        blockquote {
          background: $gray-light;
          border-left: 5px solid $gray;
          padding: 0 3px;
          margin: 5px;
        }

        blockquote:before {
          color: $gray;
          content: open-quote;
          font-size: 22px;
        }
      }

      &.self {
        margin-left: auto;
      }

      &.self:after {
        content: "";
        position: absolute;
        right: -12px;
        top: -1px;
        width: 0;
        height: 0;
        border-right: 22px solid transparent;
        border-top: 40px solid $blue;
      }

      &:not(.self):before {
        content: "";
        position: absolute;
        left: -10px;
        bottom: -1px;
        width: 0;
        height: 0;
        border-left: 21px solid transparent;
        border-bottom: 40px solid $blue;
      }
    }
  }

  .footer {
    padding: 5px;
    background: $gray-light;

    form {
      display: flex;

      label {
        cursor: pointer;
      }

      input {
        display: none;
      }
    }

    .attach-area {
      display: flex;
      justify-content: start;
      font-size: 12px;
      padding: 5px;
    }

  }


}
</style>