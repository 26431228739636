<template>

  <div>
    <div class="d-flex justify-content-between">
      <button class="btn btn-sm btn-outline-primary" @click="closeLink(); link=null; graph_keyword='';"><i
          class="bi bi-arrow-left-short me-1"></i>
        Назад
      </button>
      <span class="text-primary col-3 text-right" title="Дата появления в системе">
      {{ $moment(link.date_create).format("DD MMMM YYYY") }}</span>
    </div>
    <div class="d-flex mt-3 mb-3">
      <div class="link bg-light col">
        <h2>
          <a :href="link.link" class="text-dark" target="_blank">{{ link.link }}</a>
        </h2>
      </div>

    </div>
    <div class="d-flex">
      <strong class="me-2">Тональность</strong>
      <span
          :class="link.tone"
          :style="$func.getClass(link, true)">{{ link.get_tone_display ? link.get_tone_display : "Неразмечено" }}
              </span>
      <div class="dropdown ms-2" v-if=" $store.getters.user.user_type === 'free' && $store.getters.tariff.can_edit">
        <ul class="dropdown-menu d-block m-0 p-0 border-0 position-relative">
          <template v-for=" (tone, key) in $TONES">
          <li v-if="!tone.no_filter"
              v-on:click.prevent="link.tone = key; link.get_tone_display = tone.name;
              updateLink(link)"
              :class="`dropdown-item ${link.tone === key?`active bg-${key}`:''}`">{{ tone.name }}
          </li>
            </template>
        </ul>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-4 text-center">
        <h4><b>Когда появилась</b></h4>
        <div class="row mt-5">
          <div class="col">
            <p class="text-muted">ТОП-10</p>
            <p class="text-muted">ТОП-20</p>
            <p class="text-muted">ТОП-100</p>
          </div>
          <div class="col">
            <p class="text-muted">{{ link.top_10_date }}</p>
            <p class="text-muted">{{ link.top_20_date }}</p>
            <p class="text-muted">{{ link.top_100_date }}</p>
          </div>
        </div>
      </div>
      <div class="col-4 text-center">
        <h4><b>Позиция</b></h4>
        <div class="row mt-2 scroll-y">
          <div class="col">
            <p class="text-dark">
              <i class="yandex"></i>
            </p>
            <p class="text-muted d-flex" v-for="(key, value) in link.y_search"><span>{{ value }}</span> <b
                class="text-dark col text-right">{{ key }}</b></p>
          </div>
          <div class="col">
            <p class="text-dark text-center"><i class="google"></i></p>
            <p class="text-muted d-flex" v-for="(key, value) in link.g_search"><span>{{ value }}</span> <b
                class="text-dark col text-right">{{ key }}</b></p>
          </div>
        </div>
      </div>
      <div class="col-4 text-center">
        <h4><b>Лучшая позиция</b></h4>
        <div class="row">
          <div class="col">

            <p class="text-dark">
              <i class="yandex"></i>
            </p>
            <p class="text-muted"><b>{{ link.best_y }}</b></p>
          </div>
          <div class="col">
            <p class="text-dark"><i class="google"></i></p>
            <p class="text-muted"><b>{{ link.best_g }}</b></p>
          </div>
        </div>
      </div>

    </div>
    <button @click="linkInfo(link.id)" style="display: none">refresh</button>
    <div class="col-md-5">
      <label for="country" class="form-label">Ключевые слова</label>
      <select class="form-select" v-model="graph_keyword" id="country" required="">
        <option value="">Лучшая позиция</option>
        <option v-for="key in keywords" :value="key.id">{{ key.name }}</option>
      </select>
    </div>
   <div id="legend-container" class="legend"></div>
    <div class="chart">

      <div :style="`height: 420px; width: ${chart_data?.labels?.length*25}px;`">
        <Line
            ref="linkChart"
            id="my-chart-id"
            v-if="chart_data"
            :options="chartOptions"
            :data="chart_data"
            :plugins="[htmlLegendPlugin]"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {LinkApi} from '@/api/links'

import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, Filler, PointElement)
import htmlLegendPlugin from "@/components/legendPlugin";
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);

ChartJS.defaults.plugins.legend.display = false;
ChartJS.defaults.set('plugins.datalabels', {
  color: '#000000',
  font:{
    weight: "bold",
  },
  offset: 0,
  align: "top"
});

export default {
  name: 'LinkDetail',
  components: {
    Line
  },

  props: ["link_id", 'project_id', 'date', 'keywords', 'country_code'],
  data() {
    return {
      htmlLegendPlugin: htmlLegendPlugin,
      graph_keyword: '',
      edit_comment: false,
      link: {},
      datacollection: null,
      value: [],
      value_max: null,

    }
  },

  mounted: function () {
    this.getData();
  },
  computed: {
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        layout: {
          padding: 40
        },
        scales: {
          x:
              {
                grid: {
                  display: false
                },
              },
          y:
              {
                grid: {
                  display: false
                },

                ticks: {
                  display: false,
                },
                reverse: true,
                maxTicksLimit: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                suggestedMax: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,
                max: Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100)) + 10,

              }
        },
      }
    },
    chart_data() {
      let $this = this;
      if (this.datacollection && this.datacollection.labels) {
        let new_data = [];
        for (let i in this.datacollection.datasets) {
          let d = this.datacollection.datasets[i];
          let n_d = {
            borderColor: d.borderColor,
            data: d.data.slice($this.value[0], $this.value[1]),
            fill: d.fill,
            label: d.label,
            lineTension: d.lineTension,
            type: d.type,
          };
          new_data.push(n_d);
        }
        return {
          labels: this.datacollection.labels.slice(
              $this.value[0],
              $this.value[1]
          ),
          datasets: new_data,
        };
      } else return this.datacollection;
    },
  },

  watch: {
    graph_keyword: function () {
      if (this.link) {
        this.fillData();
      }
    },
    date: function () {
      this.getData();
    }
  },
  methods: {
    formatten(value) {
      if (this.datacollection) {
        let val = this.datacollection.labels[value];
        if (!val) val = this.datacollection.labels[value - 1]
        return `${val}`;
      }
      return '';
    },
    getData: function () {
      let $this = this;
      LinkApi.get(this.link_id, this.date).then(response => {
        $this.link = response;
        if (!$this.datacollection) this.fillData()
      })
    },
    updateLink: function (link) {
      let $this = this;
      LinkApi.update_link(link).then(() => {
        this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Данные обновлены'
        });
        $this.$parent.getKeywords($this.project_id)
      })
    },
    fillData() {
      let $this = this;
      LinkApi.get_chart_data(this.link.id, 100, this.graph_keyword).then(response => {
        let labels = response.labels;
        if (labels.length && labels.length < 30) {
          Date.prototype.addDays = function (days) {
            let date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
          };
          let now = new Date(), last = labels[labels.length - 1];

          while (labels.length < 10) {

            let last_date = new Date(now.getFullYear(), parseInt(last.split('.')[1]), parseInt(last.split('.')[0]));
            let new_date = last_date.addDays(1);
            last = `${String(new_date.getDate()).padStart(2, '0')}.${String(new_date.getMonth()).padStart(2, '0')}`;
            labels.push(last)
          }
        }
        $this.value = [0, labels.length];
        $this.value_max = labels.length;
        $this.datacollection = {
          labels: labels,
          datasets: response.data
        };

      })
    },
    closeLink() {
      this.$parent.closeLink();
    }

  }
}

</script>
<style>
.chart {
  overflow-x: scroll;
}
</style>

