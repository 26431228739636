<template>

    <div class="not-found">
        <h1>404</h1>
        <h3>Страница не найдена</h3>
        <p>
          <router-link to="/"><i class="bi bi-arrow-left-short"></i> Вернуться на главную</router-link>
        </p>
    </div>

</template>
<script>


export default {
  name: 'PageNotFound'
}
</script>
<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  height: 60vh;
  align-items: center;
  justify-content: center;
}


</style>

