<template>
  <div class="table-responsive d-flex position-relative search-results">
    <popper trigger="click"
                  :options="{ placement: 'top',modifiers: { offset: { offset: '0,10px' } } }">
            <span slot="reference" class="info-tone">
                      <span class="ms-3 pt-2">
                              <i class="bi bi-info-circle" title="Пояснение к разметке"></i>
                      </span>
                            </span>
            <template #content>
            <div class="popper text-left">
              <div class="card-body"><p class="text-danger">Красный -
                <b class="text-danger">негативная</b></p>
                <!--<p class="text-primary">Синяя - <b class="text-primary">...</b></p> -->
                <p class="text-success">Зеленая - <b class="text-success">позитивная</b></p>
                <p class="text-muted">Серая - <b class="text-muted">нерелевантная</b></p></div>
            </div>
            </template>

          </popper>
    <table class="table sticky numbers" v-if="keywords.length">
      <thead class="sticky-top table-head">
      <tr>
        <th scope="col" class="text-center no-wrap">#</th>
      </tr>
       <tr class="text-center table-sub-head">
        <td><i>-</i></td>
      </tr>
      </thead>
      <tbody>

      <tr>
        <td :class="full_url?' full-url':''">
          <table class="table result">
            <tbody>
            <tr :class="full_url?' full-url':''"
                v-for='index in Math.max(...keywords.map(x=>Math.max(...[x["searches"]["yandex"].length, x["searches"]["google"].length])))'>
              <td :class="`no-wrap link_info engine_info${full_url?' full-url':''}`" style="width: 40px">
                <div class="inline-block">
                  <div>
                    <span>{{ index }}</span>
                  </div>
                </div>
              </td>
            </tr>

            </tbody>
          </table>

        </td>
      </tr>
      </tbody>
    </table>
    <table class="table" v-for="(key, index) in keywords">
      <thead class="sticky-top table-head">
      <tr>
        <th scope="col" class="text-center no-wrap border-end" :colspan="engines.filter(x=>x.active).length">{{
            key.name
          }}
        </th>
      </tr>
         <tr class="text-center table-sub-head">
        <th v-for="(engine, index) in engines.filter(x=>x.active)"
            :class="engines.filter(x=>x.active).length === index+1?'border-end':''">
          <i :class="engine.name"></i>
        </th>

      </tr>
      </thead>

      <tbody>

      <tr :class="full_url?' full-url':''">
        <template v-for="(engine, index) in engines.filter(x=>x.active)">
          <td :class="engines.filter(x=>x.active).length === index+1?'border-end':''">
            <table class="table result">

              <tbody>
              <tr v-for="link in key.searches[engine.name]">
                <td :class="`border-bottom no-wrap link_info engine_info${full_url?' full-url':''}`"
                    :id="'link-'+link.id">
                  <popper hover offsetDistance="5">
                    <a
                        :href="link.link"
                        target="_blank"
                        :class="`domain-max${bold_link===link.link_obj_id?' fw-bold link_display':''}`"
                        @click.prevent.exact="$parent.linkInfo(link.link_obj_id, true, link, $event)"
                        @click.shift.exact="click_on_link_to_display(link.link_obj_id, $event)"
                        :style="getClass(link)" :ref="`link-${link.link_obj_id}`"
                    >{{ full_url ? link.link : link.domain }}
                    </a>

                    <template #content>
                      <div class="popper text-start">
                        <a :href="link.link">{{ link.link }}</a>
                        <br>
                        <div class="row no-wrap">
                          <div class="col">Тон</div>
                          <div class="col">
                            {{$TONES[link.tone]?.name}}
                          </div>
                        </div>
                      </div>
                    </template>
                  </popper>

                  <button class="btn btn-light copy" title="Копировать ссылку"
                          @click.prevent="copyText(link.link)">
                    <i class="bi bi-clipboard copy" @click.prevent="copyText(link.link)"></i>
                  </button>
                </td>

              </tr>

              </tbody>
            </table>
          </td>
        </template>
      </tr>

      </tbody>
    </table>
    <table class="show-more" v-if="(pager.page * pager.size)< pager.all && pager.show" @click="pager.upPage()">
      показать ещё <i class="bi bi-chevron-double-right"></i>
    </table>
  </div>
</template>

<script>

import {LinkApi} from "@/api/project";

export default {
  name: "SearchResult",
  props: {
    keywords: {type: Object},
    full_url: {type: Boolean},
    engines: {type: Object},
    project: {type: Object},
    pager: {type: Object}
  },
  components: {},
  data() {
    return {
      bold_link: '',
    }
  },
  watch: {},
  mounted() {

  },
  methods: {
    getClass: function (link) {
      if (link.error && !link.tone) return `color: #ff8c39`;
      else {
        let COLORS = this.$COLORS;
        let val = `${link.tone}_${link.kind_type}_${link.type_tone}`;
        return `color: ${COLORS[val] !== undefined ? COLORS[val] : '#000000'}`;
      }

    },
    copyText(text) {
      const $this = this;
      navigator.clipboard.writeText(text).then(function () {
        $this.$notify({
          type: 'success ',
          text: 'Ссылка скопирована'
        });
      }, function (err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    click_on_link_to_display(link_obj_id, event) {
      event.preventDefault()
      if (this.bold_link === link_obj_id) {
        this.bold_link = ''
      } else {
        this.bold_link = link_obj_id
      }
    },
    updateLink: function (link) {
      let $this = this,
          link_data = {
            id: link.link_obj_id,
            tone: link.tone,
            type_tone: link.type_tone
          }

      LinkApi.update(link_data).then(() => {
        $this.keywords.forEach(f => {
          ["yandex", "google"].forEach(e => {
            f.searches[e].forEach(y => {
              if (y.link_obj_id === link_data.id) {
                y.tone = link_data.tone
                y.type_tone = link_data.type_tone
              }
            })
          })
        })

      })
    },
  }
}
</script>
<style>
.show-more {
  position: sticky;
  background: #F4F4F4FF;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 98px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
}
</style>
<style scoped lang="scss">
.search-results{
  align-items: start;
    overflow: auto;
}
.numbers {
  background: #fff;
  z-index: 10;

  tr {
    background: #fff !important;

    &.full-url td{
      display: flex;
      align-items: center;
    }

    td {
      background: #fff !important;
    }
  }
}
table{
  border-collapse: separate;
  border-spacing: 0;
  thead{
    top: -20px;
    box-shadow: 0 5px 28px -12px rgba(0,0,0,0.51);
  }
  tr{
    height: 35px!important;
  }
}
</style>