import {ProjectApi} from '@/api/project'

const Project = {
  state: {
    projects: [],
    load: false
  },
  mutations: {
    addObject(state, object) {
      state.projects = [...state.projects, object]
    },
    updateObject(state, object) {
      let index = state.projects.indexOf(state.projects.find(x => x.id === object.id));
      if (index+1) state.projects.splice(state.projects.indexOf(state.projects.find(x => x.id === object.id)), 1, object);
      else state.projects.push(object);
    },

    setObjects(state, objects) {
      state.projects = objects.list.results;
    },
    loader(state, status) {
      state.load = status;
    },
  },
  actions: {
    updateObject({commit}, objectData) {
      if (!objectData.id) commit('addObject', objectData);
      else commit('updateObject', objectData)
    },
    
    projects({commit}) {},

    loader({commit}, status) {
      commit('loader', status)
    }
  },
  getters: {
    projects: state => state.projects,
    load: state => state.load
  }
};

export default Project;
