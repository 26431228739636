<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="col">
          <h4 class="link active">{{ project.name }}</h4>
        </li>
        <li v-if="project.stats" class="d-flex flex-row justify-content-end mb-3 col-xl-2 no-wrap">

          <div> Факт негатива
            <router-link :to="{ name: 'links_info', params: { id: project.id }, query: { state: '20',
            engine: '', tone: ['negative'], type_tone: ['new', 'offer'] }}">
              <b class="text-danger">
                {{ project.stats.negative._20 }}</b>
            </router-link>

          </div>
        </li>
      </ul>

    </div>
    <template v-if="project.stats">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-6">
            <InfoBlock v-bind:data="project.stats.negative" v-bind:status="`danger`" v-bind:image_info="0"
                       v-bind:project_id="project.id"></InfoBlock>

          </div>
          <div class="col-sm-6">
            <InfoBlock v-bind:data="project.stats.positive" v-bind:status="`success`" v-bind:image_info="0"
                       v-bind:project_id="project.id"></InfoBlock>
          </div>
        </div>

      </div>


    </template>
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <router-link :to="{ name: 'project_detail', params: { id: project.id }}"
                   class="btn btn-outline-primary btn-sm m-2">Смотреть полностью<i
          class="bi bi-arrow-right ms-2"></i></router-link>
    </div>
  </div>

</template>

<script>
import InfoBlock from '@/components/includes/InfoBlock.vue';


export default {
  name: 'FrontProject',
  props: {
    project: {type: Object}
  },
  components: {
    InfoBlock,
  },

  data() {
    return {
    }
  },


}

</script>

<style scoped>
.card {
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0;
}

</style>
