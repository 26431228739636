<template>
  <div>

    <div :class="`dropdown${multi?' multi':' single'}`">
      <button class="btn dropdown-toggle" type="button" ref="button_drop" data-bs-toggle="dropdown" data-bs-auto-close="outside"
              aria-expanded="false" v-on:click="changeError()">
        <template v-if="multi || (!multi && !model)">{{ name }}</template>
      </button>

      <template v-if="!multi">
        <span class="value" v-if="model">{{ only_value?options.filter(x=>x.id===model)[0].name:model.name }}</span>
      </template>
      <template v-else>
        <div v-if="model.length" class="d-inline">
          <span class="tag btn btn-light btn-sm" v-for="m in model">
            {{ getModelName(m) }} <a href="" v-on:click.prevent="setValue(getOption(m))"><i class="bi bi-x"></i></a>
          </span>
        </div>
      </template>

      <ul class="dropdown-menu">
        <li><span class="dropdown-item"><input v-model="search" type="text"/></span></li>
        <li><a href="" v-for="option in calc_options" :class="`dropdown-item${getActive(option)?' active':''}`"
               v-on:click.prevent="setValue(option)">{{ option.name }}</a></li>
      </ul>
    </div>

  </div>
</template>

<script>
export default {
  name: "MultiSelect",
  props: ['model', 'options', 'multi', 'name', 'model_error', 'max', 'only_value'],
  data() {
    return {
      search: "",
      modelValue: this.model
    }
  },
  computed: {
    calc_options() {
      let qs = this.options;
      if (this.search) qs = qs.filter((option) => {
        return (option.name.toLowerCase().indexOf(this.search.toLowerCase()) + 1)
      })
      return qs
    }
  },
  methods: {
    setValue(option) {
      if (this.multi) {
        let value = option;
        let index = 0, current = this.model.filter(x=>(this.only_value?x:x.id) === value.id);
        if (this.only_value) value = option.id;
        if (current) current = current[0];

        if (current) {
          index = this.model.indexOf(current);
          if (index + 1) this.model.splice(index, 1);
        }
        else {
          if (this.max && this.model.length >= this.max) this.$emit('update:model_error', `Максимальное количество выбранных элементов ${this.max}`)
          else this.model.push(value)
        }
      } else {
        this.$emit('update:model', this.only_value?option.id:option);
        this.$refs['button_drop'].click()
      }
    },
    changeError() {
      this.$emit('update:model_error', false)
    },
    getActive(option) {
      if (this.multi){
        return !!this.model.filter(x=>(this.only_value?x:x.id) === option.id).length
      }
      else{
        return !!this.model && ((!this.only_value && this.model.id === option.id) || (this.only_value && this.model === option.id))
      }
    },
    getModelName(option){
      if (this.only_value){
        let name = option;
        let opt_value = this.options.filter(x=>x.id===option)
        if (opt_value.length) name = opt_value[0].name
        return name
      }
      return option.name;
    },
    getOption(value){
      let option = value;
      if (this.only_value){
        let opt_value = this.options.filter(x=>x.id===option)
        if (opt_value.length) option = opt_value[0]
        return option
      }
      return option;
    }
  }
}
</script>

<style scoped>
.dropdown {
  width: 100%;
  text-align: left;
  border: 1px solid #ccc;
  border-radius: 5px;
  position: relative;
}

/*.dropdown-toggle:after {*/
/*  right: 9px;*/
/*  top: 15px;*/
/*  position: absolute;*/
/*}*/

.dropdown button {
  text-align: left;
  /*width: 100%;*/
}

.dropdown-menu.show, .dropdown-menu.show input {
  width: 100%;
}

.dropdown-menu.show {
  max-height: 50vh;
  overflow: overlay;

}

span.tag {
  padding: 0.25rem;
  font-size: 10px;
  margin-right: 0.25rem;
}
.dropdown.single button{
  width: 100%;
  height: 40px;
}
.dropdown.single button:after{
  float: right;
}
.dropdown.single .value{
  position: absolute;
  top: 7px;
  left: 10px;
}
.pf-code .dropdown.single .value{
font-size: 10px;
    width: 70%;
    top: 0;
}
.pf-code .dropdown-menu.show {
    width: max-content;
}

.dropdown-menu.show{
    min-width: max-content!important;
    width: max-content!important;
}
</style>
