<template>

  <main class="">
    <div class="form-signin">
      <transition name="fade">
        <div class="card" style="height: 18rem" v-if="reg_end">

          <div class="card-body text-center">
            <h4 class="card-text mb-3">
              Подтверждение регистрации прошло успешно
            </h4>
            <p>Регистрация не завершена, дождитесь подтверждения со стороны администратора системы.</p>
            <small>
                  Вы будете автоматически перенаправлены на вход через
                  <strong>{{currentTime}}</strong>
                </small>
          </div>

        </div>
      </transition>

      <div class="mt-3 text-center">
        <router-link to="/forgot_password/">Забыли пароль?</router-link>
        |
        <router-link to="/login/">Авторизация</router-link>
      </div>
    </div>
  </main>

</template>
<script>
  import {UserApi} from '../../api/user'

  export default {

    name: 'registration_activate',
    data() {
      return {
        reg_end: false,
        currentTime: 10,
        timer: null
      }
    },

    mounted: function () {
      this.startTimer();
      UserApi.check_activate(this.$route.params.activate_code).then(() => this.reg_end=true)
    },
    destroyed() {
      this.stopTimer()
    },
    methods: {
    startTimer() {
      this.timer = setInterval(() => {
        this.currentTime--
      }, 1000)
    },
    stopTimer() {
      clearTimeout(this.timer)
    },
  },
  watch: {
    currentTime(time) {
      if (time === 0) {
        this.stopTimer();
        this.$router.push({name: "login"});
      }
    }
  },

  }
</script>
<style scoped>
  main {
    display: flex;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #f5f5f5;
    height: 100%;
  }

  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }

</style>
