<template>
  <main class="dashboard">
    <div
      class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">План</h1>
      <BackButton></BackButton>
    </div>

    <ForecastDashboard v-bind:id="$route.params.id" v-bind:stat="true"/>

  </main>
</template>

<script>
  import ForecastDashboard from '@/components/includes/ForecastDashboard.vue'

  export default {
    name: 'ProjectForecast',
    components: {ForecastDashboard},

    data() {
      return {

      }
    },
    mounted: function () {


    },
    methods: {
    }
  }

</script>


