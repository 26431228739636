<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 ">

      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <a v-on:click.prevent="$func.historyBack(`/`)" type="button" class="btn btn-sm btn-primary"><i
              class="bi bi-arrow-left-short me-1"></i>
            Назад
          </a>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col-sm">
            <h3>Отчёты</h3>
          </li>

        </ul>

      </div>
      <div class="card-body links">
        <div :class="'row mb-3 align-items-center'+ (report.report_type_error?' form-group--error':'')">
          <div class="col-2">
            <label for="id_report">Отчёт</label>
          </div>
          <div class="col">
            <select v-model="report.report_type" id="id_report" class="form-select"
                    v-on:change="report.type_error = false">
              <option v-for="(type_name, type) in report_types" :value="type" :key="type">{{ type_name.name }}</option>
            </select>
            <div class="error" v-if="report.report_type_error">{{ report.report_type_error }}</div>
          </div>
        </div>

        <template v-if="report_types[report.report_type]">

          <div v-show="!(['auditor_report'].indexOf(report.report_type)+1)"
               :class="'row mb-3 align-items-center'+ (report.date_error?' form-group--error':'')">

            <div class="col-2">
              <label for="id_date">Период</label>
            </div>
            <div class="col">

              <div v-if="!report_types[report.report_type].settings.date"
                   class="d-flex justify-content-start align-items-center">

                <span class="me-2">на</span>
                <date-picker readonly v-model="report.date" ref="date"
                             :config="options"></date-picker>
              </div>
              <div v-else class="d-flex justify-content-start align-items-center">
                <span class="me-2">с</span>
                <date-picker readonly id="id_date" class="me-1" style="width: 150px" v-model="report.date_start"
                             ref="date_start"
                             :config="options"></date-picker>
                <span class="me-2">по</span>
                <date-picker readonly style="width: 150px" v-model="report.date" ref="date"
                             :config="options"></date-picker>
              </div>
              <div class="error" v-if="report.date_error">{{ report.date_error }}</div>
            </div>
          </div>
          <div v-if="report_types[report.report_type].settings.template"
               :class="'row mb-3 mt-3 align-items-center'+ (report.template_error?' form-group--error':'')">
            <div class="col-2">
              <label for="id_state">Шаблон</label>
            </div>
            <div class="col">
              <select v-model="report.template" id="id_template" class="form-select">
                <option v-for="(name, val) in template_options" :value="val">{{ name }}</option>
              </select>
              <div class="error" v-if="report.template_error">{{ report.template_error }}</div>
            </div>
          </div>

          <div v-if="report_types[report.report_type].settings.state"
               :class="'row mb-3 align-items-center'+ (report.state_error?' form-group--error':'')">
            <div class="col-2">
              <label for="id_state">ТОП</label>
            </div>
            <div class="col">
              <select v-model="report.state" id="id_state" class="form-select">
                <option v-for="state in states" :value="state">{{ state }}</option>
              </select>
              <div class="error" v-if="report.state_error">{{ report.state_error }}</div>
            </div>
          </div>
        </template>
        <div class="row mb-3 align-items-center">
          <div class="col-2">
            <label for="id_email">Отправить на Email</label>
          </div>
          <div class="col">
            <input id="id_email" class="form-control" v-model="report.email" type="text"/>
          </div>
        </div>

        <button class="btn btn-success" v-on:click="submitReport">Сформировать отчёт</button>


        <div>

        </div>

        <div class="table-responsive mt-4" v-if="reports.length">
          <table class="table dict">
            <thead>
            <tr class="">
              <th scope="col"> №</th>
              <th scope="col">Тип</th>
              <th scope="col">ТОП</th>
              <th scope="col">Период</th>

              <th scope="col">Размер</th>
              <th scope="col">Ссылка/статус</th>
              <th scope="col">Дата создания</th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in reports">
              <tr :class="object.edit?'edit':''">
                <td>{{ index + 1 }}</td>
                <td>{{ report_types[object.report_type]?.name || object.get_report_type_display }}</td>
                <td>ТОП {{ object.state }}</td>
                <td>{{ $func.momentDate(object.date, "DD.MM.YYYY") }}</td>

                <td>{{ sizeInMb(object.size) }}</td>
                <td>
                  <template v-if="object.report_file">
                    <a :href="object.report_file"><i class="bi bi-file-arrow-down"></i> Скачать отчёт</a><br>
                    <template v-if="object.sending">
                      <div class="d-flex">
                        <input v-model="object.email" type="text" placeholder="Email" class="form-control">
                        <button class="btn btn-success" v-on:click="sendToEmail(object)"><i class="bi bi-send"></i>
                        </button>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="object.email">
                        <small>Отправлен на {{ object.email }}</small>
                      </template>
                      <a v-else href="" v-on:click.prevent="object=sending = true"><i
                          class="bi bi-send"></i> Отправить на email</a>
                    </template>
                  </template>
                  <template v-else-if="object.error">
                    <span class="text-danger"><i class="bi bi-exclamation-lg"></i> Произошла ошибка</span>
                  </template>
                  <template v-else>Отчёт генерируется</template>

                </td>
                <td>{{ object.date_create }}</td>
              </tr>
            </template>

            </tbody>
          </table>

        </div>
        <template v-if="pager.length>1">
          <nav>
            <ul class="pagination justify-content-center">
              <li :class="`page-item${p==page?' active':''}`" v-for="p in pager">
                <a class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
              </li>
            </ul>
          </nav>
        </template>

      </div>
    </div>
  </main>


</template>

<script>
import {ReportApi} from '@/api/reports'


export default {
  name: 'ProjectReports',
  components: {
  },
  data() {
    let date = new Date();
    return {
      template_options: {
        dr: 'тональность - цвет ссылок',
        dr_bg: 'тональность - фон',
      },
      report_types: {
        top_search_results_report: {
          name: "Отчёт выдачи PPTX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: true
          }
        },
        user_search_result_report: {
          name: "Отчёт выдачи XLSX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: false
          }
        },
        client: {
          name: "Информация о проекте XLSX",
          settings: {
            date: false,
            state: true,
            country: false,
            template: false
          }
        }
      },
      request: {id: null, path: '', error: false},
      timerId: null,
      current_date: new Date(),
      states: [10, 20, 50, 100],
      reports: [],
      size: 20,
      project: [],
      all_count: 0,
      q_link: '',
      page: 1,
      additional: {
        link: '', countries: [], keyword: ''
      },
      old_report_type: null,
      report: {
        report_type: '',
        state: 20,
        template: 'dr_bg',
        source: 'canon_search_result',
        date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        date_start: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
        project: this.$route.params.id,
        email: ''
      },
      options: {
        format: 'YYYY-MM-DD',
        locale: 'ru',
        useCurrent: true,
        ignoreReadonly: true
      },
    }
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      return Array.from({length: page_count}, (_, i) => i + 1);
    },
  },
  watch: {
    page: {
      deep: true,
      handler() {
        this.getReportData();
      }

    },
    report: {
      deep: true,
      handler(val) {
        if (this.report_types[val.report_type] && !this.report_types[val.report_type].settings.date && val.date) {
          this.report.date_start = val.date;
        }
      }
    }
  },
  mounted: function () {
    this.getReportData()
  },

  methods: {
    sizeInMb(value) {
      let sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
      if (!value) return '0 B';
      let i = parseInt(Math.floor(Math.log(value) / Math.log(1024)));
      if (i) return Math.round(value / Math.pow(1024, i), 2) + ' ' + sizes[i];
      return "-"
    },
    getReportData() {
      let $this = this;
      ReportApi.list($this.$route.params.id, $this.page, $this.size).then(response => {
        $this.reports = response.results;
        $this.all_count = response.count;
        $this.show_reports = true;
      });
    },
    sendToEmail(report) {
      let $this = this;
      if (report.email) {
        ReportApi.send_report_to_email(report.id, report.email).then(() => {
          $this.$notify({
            group: 'alert',
            type: 'success ',
            text: `Отчёт отправлен на почту ${report.email}`
          });
          report.sending = false
        });
      } else {
        $this.$notify({
          type: 'error ',
          text: 'Введите email'
        });
      }
    },
    submitReport() {
      let $this = this, error = false, fields = Object.keys($this.report);
      for (let field in fields) {
        let key = fields[field];
        if (!(key.indexOf('error') + 1) && key !== 'email' && !$this.report[key]) {
          console.log(key)
          error = true;
          $this.report[`${key}_error`] = 'Обязательное поле'
        }
      }

      if (!error) {
        this.report.project = this.$route.params.id;

        if (this.additional) {
          this.report.additional = {};
          Object.keys(this.additional).forEach(x => {
            console.log(this.additional[x], Array.isArray(this.additional[x]))
            if (Array.isArray(this.additional[x])) this.report.additional[x] = this.additional[x].map(c => c.id)
            else if (typeof (this.additional[x]) === 'object') this.report.additional[x] = [this.additional[x].id]
            else this.report.additional[x] = this.additional[x]
          })
        }

        ReportApi.create_report(this.report).then(response => {
          this.request.id = response.id;
          this.reports.unshift(response)

          let date = new Date();
          let date_start = new Date();
          date_start = new Date(date_start.setDate(date_start.getDate() - 7));

          this.report = {
            report_type: '', state: 20,
            date: `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
            date_start: `${date_start.getFullYear()}-${date_start.getMonth() + 1}-${date_start.getDate()}`,
            project: $this.$route.params.id,
            email: '',
            template: ''
          };
          this.additional = {
            link: '', countries: [], keyword: ''
          };

          this.timerId = setInterval(() => {
            this.getReport(this.request.id)
          }, 3000);

          this.$notify({
            group: 'alert',
            type: 'success ',
            text: 'Отчёт отправлен на формирование'
          });
        }).catch(err => {
          this.report.project = this.simpleProject;
          let e = err.response.data;
          typeof (e) == 'object' && e.non_field_errors !== undefined && (e = e.non_field_errors.toString());
          this.$notify({
            group: 'alert',
            type: 'error ',
            text: e
          });
        });

      }
    },
    getReport(id) {
      const $this = this;
      ReportApi.get(id).then(resp => {
        $this.request = {...$this.request, ...resp}
        if ($this.request.error || $this.request.report_file) {
          clearInterval($this.timerId)
          let text = `Отчёт сформирован`
          let type = 'success'
          if ($this.request.error) {
            text = `Ошибка формирования отчета`
            type = 'error'
          }

          let index_report = this.reports.map(e => e.id).indexOf(resp.id);

          if (index_report > -1) {
            this.reports.splice(index_report, 1, resp)
          }


          $this.$notify({
            group: 'alert',
            type: type,
            text: text
          });

        }
      })
    },
  }
}

</script>

<style>

</style>
