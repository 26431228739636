<template>
  <main class="dashboard">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">

      <h1>Настройки профиля</h1>
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2">
          <a v-on:click.prevent="$func.historyBack('/')" type="button" class="btn btn-sm btn-primary"><i
            class="bi bi-arrow-left-short me-1"></i>
            Назад
          </a>
        </div>

      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-sm-5" v-if="!change_password">
            <h3>Личные данные</h3>
            <form @submit.prevent="updateUser" class="form-box flex-list step-32">
              <div :class="'form-floating mb-2 ' + ($v.user.first_name.$error?'form-group--error':'')">
                <input id="name" class="form-control"  v-model.trim="$v.user.first_name.$model" placeholder="Имя">
                <label for="name">Имя</label>
              </div>
              <div class="error" v-if="!$v.user.first_name.required && $v.user.first_name.$error">Введите имя</div>
              <div class="form-floating mb-2">
                <input id="email" class="form-control" disabled :value="user.email" placeholder="email">
                <label for="email">Email</label>
              </div>
<!--              <div class="form-floating mb-2">-->
<!--                <input class="form-control" disabled :value="user.organization" placeholder="Организация">-->
<!--                <label>Организация</label>-->
<!--              </div>-->
              <div class="form-floating mb-2">
                <button class="w-100 btn btn-success" type="submit">Сохранить</button>
              </div>


            </form>
            <button class="w-100 btn btn-primary blue-bg mt-3" v-on:click.prevent="change_password=!change_password" type="button">Изменить пароль</button>
          </div>
          <div class="col-sm-5" v-else>
            <form @submit.prevent="changePassword" class="form-box flex-list step-32 mt-3">
              <h3>Изменение пароля</h3>
              <div :class="'form-floating mt-2 ' + ($v.password.old.$error?'form-group--error':'')">
                <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.password.old.$model"
                       placeholder="Пароль">
                <label>Текущий пароль</label>
                <i v-on:click="show_pass=!show_pass"
                   :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <span class="bi-place"></span>
              </div>
              <div class="error" v-if="!$v.password.old.required && $v.password.old.$error">- Введите текущий пароль</div>


              <div :class="'form-floating mt-2 ' + ($v.password.newp.$error?'form-group--error':'')">
                <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.password.newp.$model"
                       placeholder="Пароль">
                <label>Новый пароль</label>
                <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <span class="bi-place">

          </span>
              </div>
              <div class="form-group__message">
                <div class="error" v-if="!$v.password.newp.required && $v.password.newp.$error"> - Введите новый пароль</div>
                <div class="error" v-if="!$v.password.newp.truePassword && $v.password.newp.$error">
                  - Пароль должен содержать заглавные и строчные буквы, цифры и специальные символы
                </div>
                <div class="error" v-if="!$v.password.newp.minLength && $v.password.newp.$error">
                  - Пароль должен содержать не менее 8 символов
                </div>
              </div>


              <div :class="'form-floating mt-2 ' + ($v.password.repeat.$error?'form-group--error':'')">
                <input class="form-control" :type="show_pass?'text':'password'" v-model.trim="$v.password.repeat.$model"
                       placeholder="Пароль">
                <label>Новый пароль</label>
                <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
                <span class="bi-place"></span>
              </div>
              <div class="form-group__message">
              <div class="error" v-if="!$v.password.repeat.required && $v.password.repeat.$error && password.newp.length">- Повторите введённый пароль
              </div>
              <div class="error"
                   v-if="!$v.password.repeat.sameAsPassword && $v.password.repeat.$error && password.repeat.length && password.newp.length">
                - Введённые пароли не совпадают
              </div>
              </div>


              <div class="form-floating mb-2 mt-2">
                <button class="w-100 btn btn-success" type="submit">Изменить пароль</button>
                <button class="w-100 btn btn-primary mt-3" v-on:click.prevent="change_password=!change_password" type="button">Отмена</button>
              </div>


            </form>
          </div>
        </div>


      </div>
    </div>

  </main>


</template>

<script>
  import {required, minLength, sameAs} from '@vuelidate/validators'
  import {UserApi} from '@/api/user'

  const truePassword = (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W\w])[A-Za-z\d\W\w]{8,}$$/.test(value);
  export default {
    name: 'UserSettings',
    data() {
      return {
        show_pass: false,
        change_password: false,
        password: {newp: '', old: '', repeat: ''}
      }
    },
    components: {
    },
    validations: {
      password: {
        newp: {
          required,
          minLength: minLength(8),
          truePassword,
        },
        old: {
          required,
        },
        repeat: {
          required,
          sameAsPassword: sameAs('newp')
        }
      },
      user: {
        first_name: {
          required,
        },

      }
    },
    computed: {
      user: {
        get() {
          return this.$store.getters.user
        },
        set(value) {
          this.$store.dispatch('update_user', value)
        }
      }
    },
    mounted: function () {

    },
    methods: {
      updateUser: function () {
        this.$v.$touch();
        let $this = this;
        if (!this.$v.user.$invalid) {
          this.$store.dispatch('update_user', this.user).then(()=>{
            $this.$notify({
              group: 'alert',
              type: 'success ',
              text: 'Информация обновлена'
            });
          })
        }
      },
      changePassword: function () {

        let $this = this;
        this.$v.$touch();
        if (!this.$v.$invalid) {
          UserApi.update_password($this.password).then(response => {
            if (response.data['errors']) {
              for (let i in response.data['errors']) {
                $this.$notify({
                  group: 'alert',
                  type: 'error ',
                  text: response.data['errors'][i]
                })
              }
            }
            else {
              let username = $this.user.email, password = $this.password.newp;
              this.$notify({
              group: 'alert',
              type: 'success ',
              text: 'Пароль обновлён'
            });
              this.$store.dispatch('login', {
              username,
              password
            }).then(() => {
              $this.password = {newp: '', old: '', repeat: ''};
              this.$v.$reset();
            });
            }
          });

        }


      }
    }
  }

</script>
