<template>
  <div class="btn-group countries ms-2 me-2" role="group" aria-label="Страны проекта">
    <button type="button" v-for="country in countries" 
      :class="active_country.code===country.code?'btn btn-sm btn-success':'btn btn-sm btn-outline-success'"
      @click="changeCountry(country, project_id)">
      {{ country.name }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CountryChoice",
  props: ['project_id', 'active_country', 'countries', 'changeCountry'],
  data() {
    return {}
  },


}
</script>

<style scoped>

</style>
