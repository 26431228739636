<template>
    <ul class="nav nav-tabs" style="padding-right: 80px">
        <li class="nav-item" v-for="menu in menu_list.main">
            <router-link :to="menu.path" :class="`nav-link ps-2 pe-2 ${route === menu.path.name?'active':''}`">
                {{ menu.name }}
            </router-link>
        </li>
        <li class="nav-item" v-if="menu_list.dropdown.length">
            <div class="dropdown p-2">
                <a class="dropdown-toggle p-0" href="#" id="dropdownProjectMenuLink" data-bs-toggle="dropdown"
                   aria-expanded="false">
                    <i class="bi bi-list"></i>
                </a>
                <ul class="dropdown-menu" aria-labelledby="dropdownProjectMenuLink">
                    <li class="nav-item" v-for="menu in menu_list.dropdown">
                        <router-link :to="menu.path" active-class="active" class="nav-link">
                            {{ menu.name }}
                        </router-link>
                    </li>
                </ul>
            </div>

        </li>

        <li>
            <BackButton></BackButton>
        </li>
    </ul>

</template>

<script>
export default {
    name: "ProjectMenu",
    props: ['id', 'route', 'role', 'project'],
    data: () => ({
        width: window.innerWidth,
    }),
    computed: {
        menu_list() {
            let menu_list = [
                // {name: '1. Ссылки', show: true, path: { name: 'project_detail', params: { id: this.id }}},
                // {name: '2. Подсказки', show: true, path: { name: 'project_hints', params: { id: this.id }}},
                // {name: '3. Wikipedia', show: true, path: { name: 'dev'}},
                // {name: '4. Изображения', show: this.project.project_type === 'active', path: { name: 'project_images_info', params: { id: this.id }}},
                // {name: '5. Видео', show: this.project.project_type === 'active', path: { name: 'dev'}},
                // {name: '6. Блок знаний', show: true, path: {name: 'project_knowledge_block', params: { id: this.id }}},
                // {name: '7. Похожие запросы', show: true, path: { name: 'project_related_requests', params: { id: this.id }}},
                // {name: 'Выдача изображений', show: true, path: { name: 'project_pictures', params: { id: this.id }}},
                // {name: 'Backlinks', show: true, path: { name: 'project_backlinks', params: { id: this.id }}},
                // {name: 'Юр.работы', show: true, path: { name: 'dev'}},
                // {name: 'Отчёты', show: true, path: { name: 'project_reports', params: { id: this.id }}},
                // {name: 'IBot', show: this.project.project_type === 'active', path: { name: 'ibot', params: { id: this.id }}},
            ]
            menu_list = menu_list.filter(x=>x.show)
            let sizes = {1920: 12, 1750: 10, 1600: 10, 1550: 7, 1440: 7, 1200: 6, 1100: 4}
            let count = Object.keys(sizes).filter(x=>x<=this.width).sort().reverse()[0]
            return {main: menu_list.slice(0, sizes[count]), dropdown: menu_list.splice(sizes[count])}
        }
    },
    created() {
        window.addEventListener("resize", this.changeWidth);
    },
    destroyed() {
        window.removeEventListener("resize", this.changeWidth);
    },
    methods: {
        changeWidth() {
            this.width = window.innerWidth
        }
    }
}
</script>

<style scoped>

</style>
