<template>

  <main class="form-signin">
    <form @submit.prevent="restore_password">
      <div class="breadcrumb text-white mb-4">
        <a :href="$store.getters.current_domain">Главная</a> / <span>Восстановление пароля</span>
      </div>
      <h3 class="h3 mb-3 text-white text-center">Восстановление пароля</h3>
      <div class="card" style="height: 18rem" v-if="reg_end">
        <div class="card-body">
          <h4 class="card-text" style="position: absolute;top: 25%; text-align: center">
            Инструкции по восстановлению пароля отправлены на указанный email.
          </h4>
        </div>
      </div>
      <template v-else>
        <div
            :class="'form-floating '">
          <input class="form-control" required name="email" type="email" v-model="username" placeholder="Email">

          <span class="bi-place">
          <popper trigger="click">
            <i :class="`bi bi-info-circle text-white`"></i>
            <template #content>
                            <div class="popper text-left">
                              Введите email, на который был зарегистрирован личный кабинет

                            </div>
            </template>
                          </popper>
</span>

          <label>Email</label>
        </div>
        <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Восстановить</button>
      </template>
      <div class="d-flex justify-content-around mt-2">
        <router-link to="/login/">Авторизация</router-link>
        |
        <router-link to="/registration/">Регистрация</router-link>
      </div>
    </form>
  </main>

</template>
<script>
import {UserApi} from '@/api/user'

export default {

  name: 'ForgotPassword',
  data() {
    return {
      username: "",
      reg_end: false,
    }
  },
  components: {},

  mounted: function () {
    if (localStorage.getItem('username')) this.username = localStorage.getItem('username');
  },
  methods: {
    restore_password: function (e) {
      e.preventDefault();
      let $this = this;
        UserApi.restore_password(this.username).then(response => {
          if (response.data['errors']) {
            for (let i in response.data['errors']) {
              $this.$notify({
                type: 'error',
                text: response.data['errors'][i]
              })
            }
          } else $this.reg_end = true;

        });

    }
  }
}
</script>

<style scoped lang="scss">
.bi-place {
  cursor: pointer;
  i{
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    position: absolute;
  }
}
</style>
