<template>
  <div class="div" v-if="diff_date.show">
    <button class="btn btn-sm btn-outline-primary" @click="diff_date.show=false;"><i
      class="bi bi-arrow-left-short me-1"></i>
      Назад
    </button>
    <template v-if="diff_date.start_obj && diff_date.end_obj">
      <div v-for="tone in tones" :class="`table-responsive row border rounded border-${tone.style} m-2 p-2`">
        <div :class="`h5 text-center text-${tone.style}`">{{ tone.name }}</div>
        <div class="col-6" v-for="type in types">

          <table class="table table-borderless">
            <thead>
            <tr class="text-center">
              <th></th>
              <th :class="`bordered border-${tone.style}`">{{ diff_date.start }}</th>
              <th :class="`bordered border-${tone.style}`">{{ diff_date.end }}</th>
              <th> &gt;&lt;</th>
            </tr>
            </thead>
            <tbody v-for="top in tops" class="mt-2">
            <template v-if="state>=top">
              <tr>
                <td colspan="2"><u>{{ type.name }} в ТОП {{ top }}</u></td>
                <td colspan="2"></td>
              </tr>
              <tr>
                <td></td>
                <td v-for="_ in [1,2,3]" :class="`${_<3?`bordered border-${tone.style}`:' bg-warning-subtle'}`">
                  <table class="inner">
                    <tr>
                      <td>Всего</td>
                      <td>Ya</td>
                      <td>G</td>
                    </tr>
                  </table>
                </td>

              </tr>
              <tr v-for="uniq in uniques">
                <td :class="`bordered border-${tone.style}`">{{ uniq.name }}</td>
                <td :class="`bordered border-${tone.style}`">
                  <table class="inner">
                    <tr>
                      <td>{{ diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}`] }}</td>
                      <td>{{ diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}_y`] }}</td>
                      <td>{{ diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}_g`] }}</td>
                    </tr>
                  </table>
                </td>
                <td :class="`bordered border-${tone.style}`">
                  <table class="inner">
                    <tr>
                      <td>{{ diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}`] }}</td>
                      <td>{{ diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}_y`] }}</td>
                      <td>{{ diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}_g`] }}</td>
                    </tr>
                  </table>
                </td>
                <td class="bg-warning-subtle">

                  <table class="inner">
                    <tr>
                      <td>
                        <b :class="`text-${getClass(tone.val, `negative_${top}${type.val}${uniq.val}`)}`">
                        {{
                          diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}`] - diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}`]
                        }}
                          </b>
                      </td>
                      <td>
                        <b :class="`text-${getClass(tone.val, `negative_${top}${type.val}${uniq.val}_y`)}`">
                        {{
                          diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}_y`] - diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}_y`]
                        }}
                          </b>
                      </td>
                      <td>
                        <b :class="`text-${getClass(tone.val, `negative_${top}${type.val}${uniq.val}_g`)}`">
                        {{
                          diff_date.end_obj[tone.val][`negative_${top}${type.val}${uniq.val}_g`] - diff_date.start_obj[tone.val][`negative_${top}${type.val}${uniq.val}_g`]
                        }}
                          </b>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

        </div>
      </div>
    </template>

  </div>
</template>

<script>
import {ProjectApi} from "@/api/project";
import moment from "moment/moment";

export default {
  name: "DiffDates",
  props: ['diff_date', 'project_id', 'active_country', 'state', 'start', 'end'],
  components: {
  },
  data() {
    return {
      tops: [10, 20, 50, 100],
      tones: [
        {style: 'danger', val: 'negative', name: 'Нецелевая информация'},
        {style: 'success', val: 'positive', name: 'Целевая информация'}
      ],
      types: [{name: 'Всего', val: ''}, {name: 'Договор', val: '_offer'}],
      uniques: [{name: 'Общий', val: ''}, {name: 'Уникальный', val: '_uniq'}]
    }
  },
  watch: {
    active_country(){
      this.showDiff()
    },
    start(){
      this.showDiff()
    },
    end(){
      this.showDiff()
    }
  },
  mounted() {
    this.showDiff()
  },
  methods: {
    showDiff: function () {
      if (this.diff_date.start && this.diff_date.end) {
        let $this = this;
        let start = moment($this.diff_date.start, 'DD/MM/YYYY');
        start = `${start.year()}-${start.month() + 1}-${start.date()}`
        // start = `2023-01-01`
        ProjectApi.get_full($this.project_id, start, $this.active_country || 'RU').then(response => {
          $this.diff_date.start_obj = response;
          $this.diff_date.start_obj = {...$this.diff_date.start_obj, ...$this.diff_date.start_obj.stats};
        });
        let end = moment($this.diff_date.end, 'DD/MM/YYYY');
        end = `${end.year()}-${end.month() + 1}-${end.date()}`
        // end = `2023-01-10`
        ProjectApi.get_full($this.project_id, end, $this.active_country || 'RU').then(response => {
          $this.diff_date.end_obj = response;
          $this.diff_date.end_obj = {...$this.diff_date.end_obj, ...$this.diff_date.end_obj.stats};
        });
        $this.diff_date.show = true;
      }
    },
    getClass(tone, key){
      if ((this.diff_date.end_obj[tone][key] - this.diff_date.start_obj[tone][key])>0){
        return tone==='positive'?'success':'danger'
      }
      else return tone==='positive'?'danger':'success'
    }
  }
}
</script>

<style scoped>
.bordered{
  border-right: 1px solid #ccc;
  margin-right: 10px;
}
.inner{
  width: 100%;
  text-align: center;
}
.inner td{
  width: 66px;
}
</style>
