import { HTTP } from "./common";

export const ReportApi = {
  list(project_id,page, size) {
    return HTTP.get(`user/reports/?project_id=${project_id}&page=${page}&size=${size}`).then((response) => {
      return response.data;
    });
  },
 
  get(id) {
    return HTTP.get(`user/reports/${id}/`).then((response) => {
      return response.data;
    });
  },
  create_report(report) {
    return HTTP.post(`user/reports/`, report).then((response) => {
      return response.data;
    });
  },
  send_report_to_email(id, email) {
    return HTTP.get(`user/reports/${id}/send_report_to_email/?email=${email}`).then(
      (response) => {
        return response.data;
      }
    );
  },
  get_report_setting() {
    return HTTP.get(`user/reports/get_report_setting/`).then(
      (response) => {
        return response.data;
      }
    );
  },
};
