<script setup>
import {computed, ref, onMounted, onUnmounted} from 'vue'
import {useStore} from 'vuex'
import {notify} from "@kyvg/vue3-notification";
import {useRoute} from 'vue-router';
import {UserApi} from "@/api/user";

const route = useRoute()
const store = useStore()


onMounted(() => {
  document.body.classList.add('full-width')
})
onUnmounted(() => {
  document.body.classList.remove('full-width')
})


const remember = computed(() => store.getters.remember)

const user = {
  username: remember.value,
  password: "",
  remember: !!remember.value
}
const show_pass = ref(false);

function login(e) {
  e.preventDefault();
  store.dispatch('login', user).then(() => {
    location.search = "";
    location.pathname = route.query.next || '/';
  }).catch(err => {
    let err_text = 'Данные неверны';
    if (err.response.status === 429) err_text = 'Превышено количество попыток ввода пароля';
    notify({
      type: 'error ',
      title: "Ошибка!",
      text: err_text,
    });

  })
}


</script>

<template>

  <main class="form-signin">

    <form @submit="login">
      <div class="breadcrumb text-white mb-4">
        <a :href="$store.getters.current_domain">Главная</a> / <span>Авторизация</span>
      </div>
      <h3 class="h3 mb-3 text-white text-center">Авторизация</h3>

      <div class="mb-3">
        <input type="email" name="email" class="form-control grey" autocomplete="on" required v-model="user.username"
               placeholder="Логин">
      </div>
      <div class="mb-3 password">
        <input class="form-control grey" id="password" :type="show_pass?'text':'password'" name="password" required
               v-model="user.password" placeholder="Пароль">
        <i v-on:click="show_pass=!show_pass" :class="`bi bi-eye${show_pass?' active':''}`"></i>
      </div>

      <div class="checkbox mb-3">
        <input id="id_remember" class="black" type="checkbox" v-model="user.remember" value="remember-me">
        <label class="text-white" for="id_remember">
          Запомнить
        </label>
      </div>
      <button class="w-100 btn btn-lg btn-primary text-white" type="submit">Вход</button>
      <div class="forget">
        <router-link to="/forgot_password/" class="text-center p-2">Забыли пароль?</router-link>
        |
        <router-link to="/registration/" class="text-center p-2">Регистрация</router-link>
      </div>
    </form>


  </main>

</template>

<style scoped lang="scss">
.forget {
  display: flex;
  justify-content: space-around;
}
</style>
