<template>
  <main class="dashboard">

    <div class="card">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item col-auto">
            <h3>{{ object.name }}
            </h3>
          </li>

          <li class="d-flex flex-row mb-3 col flex-wrap justify-content-end">
            <router-link type="button"
                         v-if=" $store.getters.user.user_type === 'free' && $store.getters.tariff.can_edit"
                         :to="`/projects/${$route.params.id}/edit/`"
                         class="btn btn-sm ms-2 btn-outline-primary">
              Редактировать
            </router-link>

            <router-link type="button" class="btn btn-sm ms-2 btn-outline-primary"
                         :to="{ name: 'links_info', params: { id: $route.params.id }, query: {tone:['negative'], type_tone: ['offer'], uniq: true, page_name: 'Негативные публикации на отслеживание'}}">
              Негативные публикации на отслеживание
            </router-link>
            <router-link type="button" v-if="$store.getters.user.user_type === 'free' && $store.getters.tariff.can_edit"
                         class="btn ms-2 btn-outline-primary btn-sm"
                         :to="{ name: 'project_report', params: { id: $route.params.id }}"
            >Отчёты
            </router-link>
            <router-link type="button" class="btn  ms-2  btn-outline-primary btn-sm"
                         :to="{ name: 'links_info', params: { id: $route.params.id }, query: {uniq: true}}"
            >Уникальные ссылки
            </router-link>

          </li>
        </ul>

      </div>
      <div class="card-body">
        <div class="row pb-2">
          <div class="col">
            <CountryChoice
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="object.countries"
              v-bind:changeCountry="changeCountry">
          </CountryChoice>
          </div>
          <div class="col">
             <date-picker v-model="active_date"
                       locale="ru"
                       :clearable="false"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       auto-apply
                       :allowed-dates="allowedDates"
                       :enable-time-picker="false"></date-picker>
          </div>
          <div class="col text-end">
            <span class="pe-2 text-muted">Проект в работе с <b
              class="text-dark" v-if="object.date_create">{{ $moment(object.date_create).format("DD MMMM") }}</b></span>
          </div>



        </div>

        <div class="row" v-if="object.stats">
          <div class="col-sm-6">
            <info_block v-bind:data="object.stats.negative" v-bind:status="`danger`"
                        v-bind:show_more="full_info_show"
                        v-bind:project_id="object.id" v-bind:date="active_date"></info_block>
          </div>
          <div class="col-sm-6">
            <info_block v-bind:data="object.stats.positive" v-bind:status="`success`"
                        v-bind:show_more="full_info_show"
                        v-bind:project_id="object.id" v-bind:date="active_date"></info_block>
          </div>
        </div>
        <div class="mt-4">
          <button @click="getFull()" class="btn btn-outline-dark">
            <template v-if="full_info_show">Скрыть</template>
            <template v-else>Показать</template>
            больше выборок
          </button>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="d-flex">

              <div class="card-body " v-if="object.stats">

                <p class="text-muted">Новый атоматический негатив в ТОП-20:
                  <router-link :to="{ name: 'links_info', params: { id: $route.params.id }, query: { state: 20, uniq: true,
                                 engine: '', tone: ['negative'], type_tone: ['new'], kind_type: ['auto'], date: active_date, new: true}}">
                    <b class="text-danger">{{ object.stats.new_negative }} </b>
                  </router-link>
                </p>

                <p class="text-muted">Автоматически размечено в ТОП-20:
                  <router-link :to="{ name: 'links_info', params: { id: $route.params.id }, query: { state: 20, uniq: true,
                                 engine: '', tone: [], kind_type: ['auto'], date: active_date}}">
                    <b class="text-primary">{{ object.stats.auto_mark }} </b>
                  </router-link>
                </p>
                <p class="text-muted">Неразмеченных ссылок в ТОП-20:
                  <router-link :to="{ name: 'links_info', params: { id: $route.params.id }, query: { state: 20,
                                 engine: '', tone: ['null'], date: active_date, uniq: true}}">
                    <b class="text-danger">{{ object.stats.no_mark }} </b>
                  </router-link>
                </p>
                <p class="text-muted">% негатива в сравнении с прошлой неделей:
                  <b class="text-success">{{ object.stats.percent_week }}% </b>
                </p>
                <p class="text-muted">% негатива в сравнении с прошлым месяцем:
                  <b class="text-success">{{ object.stats.percent_month }}% </b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="">
              <div class="card-body" v-if="object.id">
                <SmallReport v-bind:project_id="object.id"
                             v-bind:date_start="active_date"
                             v-bind:date="active_date"
                             v-bind:state="state"
                             v-bind:country_id="active_country.id"></SmallReport>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row border-0 pb-1">
      <div class="col-sm justify-content-between justify-content-md-start d-flex  pt-1 flex-wrap">
        <button v-for="top in tops" type="button" @click="state=top"
                :class="`btn btn-sm btn-outline-primary me-1${state===top?' active':''}`">
          ТОП-{{ top }}
        </button>
        <div class="d-sm-flex ps-1" style="position:relative;">
          <date-picker v-model="active_date"
                       locale="ru"
                       :clearable="false"
                       format="dd.MM.yyyy"
                       model-type="yyyy-MM-dd"
                       auto-apply
                       :allowed-dates="allowedDates"
                       :enable-time-picker="false"></date-picker>

          <CountryChoice
              v-bind:active_country.sync="active_country"
              v-bind:project_id="$route.params.id"
              v-bind:countries="object.countries"
              v-bind:changeCountry="changeCountry">
          </CountryChoice>

          <div class="form-check form-switch ms-3 pt-2 d-inline-block">
            <input class="form-check-input" v-model="full_url" type="checkbox" role="switch" id="full_url" checked>
            <label class="form-check-label fz-9" for="full_url">Полные <br>ссылки</label>
          </div>
        </div>
      </div>


      <div class="col-sm-2 diff justify-content-between justify-content-md-end d-flex  pt-1" style="position: relative">
        <date-picker v-model="diff_date.start"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false"></date-picker>

        <i class="bi bi-arrow-right m-2" style="color: #0F41D9"></i>
        <date-picker v-model="diff_date.end"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :allowed-dates="allowedDates"
                     :enable-time-picker="false"></date-picker>
        <button type="button" class="btn btn-primary blue-bg ms-2 btn-sm"
                @click="diff_date.show=(!!diff_date.start&&diff_date.end)">Сравнить
        </button>
      </div>

    </div>

    <div class="card m-1 border-0 mb-3">

      <div class="card-body border border-primary">
        <DiffDates v-if="diff_date.show"
                   v-bind:diff_date="diff_date"
                   v-bind:project_id="$route.params.id"
                   v-bind:start="diff_date.start"
                   v-bind:end="diff_date.end"
                   v-bind:active_country="active_country.code" v-bind:state="state">
        </DiffDates>

        <LinkDetail v-else-if="link"
                    v-bind:link_id="link"
                    v-bind:date="active_date"
                    v-bind:audit="true"
                    v-bind:country_code="active_country.code"
                    v-bind:project_id="$route.params.id" v-bind:keywords="keywords">
        </LinkDetail>


        <SearchResult v-else
                      :project="object"
                      :keywords="keywords"
                      :full_url="full_url"
                      :engines="engines"
                      :pager="{all: all_count, page: page, size: per_page, show: show_pager, upPage:upPage}"
        ></SearchResult>

      </div>
    </div>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import moment from 'moment';
import LinkDetail from '@/components/includes/LinkDetail.vue'
import InfoBlock from '@/components/includes/InfoBlock.vue';
import CountryChoice from '@/components/CountryChoice';
import DiffDates from "@/components/includes/DiffDates.vue";
import SmallReport from "@/components/includes/SmallReport.vue";
import SearchResult from "@/views/project/includes/SearchResult.vue";


export default {
  name: 'ProjectDetail',
  components: {
    'info_block': InfoBlock,
    LinkDetail, CountryChoice, DiffDates, SmallReport, SearchResult
  },

  data() {
    return {
      object: {},
      active_date: null,
      old_active_date: null,
      keywords: [],
      state: 20,
      tops: [10, 20, 50, 100],
      full_info: false,
      full_info_show: false,
      page: 1,
      per_page: 10,
      all_count: 0,
      show_pager: false,
      allowedDates: [],
      active_country: {code: null, yandex: true, google: true},
      full_url: false,
      diff_date: {start: '', end: '', start_obj: {}, end_obj: {}, show: false},
      link: null,
      engine: this.$ENGINES.map(x => {
        return {name: x, active: true}
      }),

    }
  },
  computed: {
    date_query() {
      if (this.active_date?.includes("/")) return moment(this.active_date, 'DD/MM/YYYY').format("YYYY-MM-DD");
      return this.active_date;
    },
    engines() {
      return this.engine.filter(x => this.active_country ? this.active_country[x.name] : false)
    }
  },
  watch: {
    $route() {
      this.full_info = false;
      this.page = 1
      this.getProject()
    },
    active_date: function (val) {
      if (this.old_active_date !== null) this.getProject();
      this.page = 1
      this.old_active_date = val;
    },
    state: function () {
      this.page = 1
      this.getKeywords();
    },
  },
  mounted: function () {
    let $this = this;
    if (this.$route.query.link) this.linkInfo(this.$route.query.link, false);
    this.getProject();
    ProjectApi.get_avail_date($this.$route.params.id, $this.date_query).then(response => {
      $this.allowedDates = response;
    });
  },
  methods: {
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.page = 1
        this.full_info = false;
        this.full_info_show = false;
        this.getProject();
      }
    },
    getProject: function () {
      let $this = this;
      ProjectApi.get($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU').then(response => {
        $this.object = response;
        if (!$this.active_country.code) $this.active_country = $this.object.countries[0];
        if (!$this.active_date) {
          $this.active_date = moment($this.object.last_parser).format("YYYY-MM-DD");
        }
        $this.getKeywords()
      });
    },
    getKeywords: function () {
      let $this = this;
      ProjectApi.keywords($this.$route.params.id, $this.state, $this.date_query, $this.active_country.code || 'RU', this.per_page, this.page).then(response => {
        if (this.page > 1) $this.keywords = $this.keywords.concat(response.results);
        else $this.keywords = response.results;
        $this.all_count = response.count;
        $this.show_pager = true;

      })
    },
    getFull: function () {
      let $this = this;
      if (!$this.full_info) {
        ProjectApi.get_full($this.$route.params.id, $this.date_query, $this.active_country.code || 'RU').then(response => {
          $this.full_info = true;
          $this.full_info_show = true;
          $this.object.stats = response;
        });
      } else $this.full_info_show = !$this.full_info_show;
    },

    closeLink: function () {
      history.pushState({}, null, `/cabinet${this.$route.path}`);
      this.link = null;
    },

    linkInfo: function (id, state = true) {
      this.link = id;
      if (state) history.pushState({}, null, `/cabinet${this.$route.path}?link=${id}`);
    },
    fileResponse: function (response, name) {
      let blob = new Blob([response], {type: 'application/vnd.ms-excel'});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.click();
    },

    getFileResult: function () {
      let $this = this;
      ProjectApi.search_result_download($this.$route.params.id, {
        state: $this.state,
        date: $this.date_query,
        country_code: $this.active_country.code
      }).then(response => {
        $this.fileResponse(response, `search_result-${$this.date_query}.xlsx`)
      });
    },
    upPage() {
      this.page += 1;
      this.show_pager = false;
      this.getKeywords()
    },

    getFileInfo: function () {
      let $this = this;
      ProjectApi.project_download_info($this.$route.params.id, {
        date: $this.date_query,
        country_code: $this.active_country.code
      }).then(response => {
        $this.fileResponse(response, `report-${$this.date_query}.xlsx`)
      });
    },

  }
}

</script>

<style>
.date {
  height: 100px;
  margin: 20px 0;
}

.link {
  cursor: pointer;
}

td {
  max-width: 400px;
}

.table.abs {
  position: absolute;
  left: 2px;
  width: 15px;
  background: #fff;
}

.head {
  width: 105px;
  flex: 0 0 105px;
}

.place {
  width: 40px;
  text-align: left;
  margin-left: 0;
  flex: 0 0 40px;
}

.card-body {
  padding: 15px;
}

.scroll-y {
  height: 250px;
  overflow-y: scroll;
}

.no-wrap.full-url {
  white-space: normal;
  height: 130px;
}

.card-header-tabs li a {
  white-space: nowrap;
  height: 30px;
}

.popper.text-left {
  max-width: 300px;
  white-space: normal;
}

.info-tone {
  position: absolute;
  right: 15px;
  top: 10px;
}

.short-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  display: block;
}

.engine_info {
  height: 20px;
  padding: 7px;
}

table.sticky {
  position: sticky;
  left: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.show-more {
  position: sticky;
  background: #F4F4F4FF;
  right: 0;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 5px;
  width: 98px;
  text-align: center;
  cursor: pointer;
  z-index: 10;
}
</style>


