import { HTTP } from './common'

export const UserApi = {
  get_info(){
    return HTTP.get(`/users/get_info/`)
  },

  registration(user){
    return HTTP.post('/auth/registration/', user)
  },

  check_activate(code){
    return HTTP.post('/auth/check_activation/', {code: code})
  },

  restore_password(email){
    return HTTP.post('/auth/restore_password/', {email: email})
  },

  check_token(uid, token){
    return HTTP.post('/auth/check_token/', {uid: uid, token: token})
  },

  change_password(data){
    return HTTP.post('/auth/change_password/', data)
  },

  update_password(data){
    return HTTP.post('/users/update_password/', data)
  },
  update_user(data){
    return HTTP.post('/users/update_user/', data)
  },
  tariff_info(){
    return HTTP.get('/users/get_tariff/')
  },

  alert_list(){
    return HTTP.get('/users/alert_list/')
  },
  read_alert(){
    return HTTP.post('/users/read_alert/')
  },

  menu(){
    return HTTP.post('/users/services/')
  },


};


