<template>
  <main class="dashboard">

    <div class="card">
      <div class="card-body">
        <h5 class="card-title">{{ object?.text }}</h5>
        <small>{{ object?.date }}</small>
        <div class="content-area" v-if="object?.content">
          <div class="content-wrapper">
            <div class="content-body">
              <template v-for="(element, index) in parsed_content">
                <div :class="'element' + (element.edit?' focus':'') + (element.comment?' hover':'')">
                  <span class="text" v-html="element.text"></span>
                  <button v-if="!element.comment" class="btn btn-sm btn-outline-dark add-comment"
                          title="Добавить комментарий"
                          v-on:click="element.comment = {open: true};">+
                  </button>
                  <div class="comment-small" v-if="element.comment && !element.comment?.open"
                       @click="element.comment.open = true"><i class="bi bi-chat-left-dots"></i></div>
                  <form @submit.prevent="addComment(index, element)" class="comment" v-on:mouseenter="element.edit=true"
                        v-on:mouseleave="element.edit = false;" v-if="element.comment && element.comment?.open">
                    <div v-if="element.comment.date_update" class="date">
                      {{ $moment(element.comment.date_update).format("DD.MM.YYYY HH:mm") }}

                    </div>
                    <a v-if="element.comment.id" @click.prevent="element.comment.open=false" class="close">
                      <i class="bi bi-x-lg"></i>
                    </a>
                    <textarea required name="" v-model="element.comment.text"
                              placeholder="Добавить комментарий"></textarea>
                    <div class="d-flex" v-if="element.edit">
                      <button class="btn btn-success btn-sm" type="submit" title="Сохранить"><i class="bi bi-check"></i>
                      </button>
                      <button class="btn btn-danger btn-sm" type="button" v-on:click="deleteComment(element)"
                              title="Удалить"><i
                          class="bi bi-trash3"></i></button>
                    </div>
                  </form>
                </div>
              </template>
            </div>
            <div class="chat">
              <Chat :object_id="object.id" :content_type="object.content_type" :chat_id="getChatId"></Chat>
            </div>
          </div>

        </div>

      </div>


    </div>


  </main>
</template>

<script>
import {DictionaryApi} from '@/api/dictionary'
import Chat from "@/components/chat/Chat.vue";
import {ChatApi} from "@/components/chat/api";

export default {
  name: 'ContentDetail',
  data() {
    return {
      name: 'contents',
      object: null,
      parsed_content: [],
      chat_id: null
    }
  },
  components: {Chat},
  mounted: function () {
    DictionaryApi.get_detail(this.name, this.$route.params.id).then(response => {
      this.object = response
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(this.object.content.text, 'text/html');
      htmlDoc.querySelector("body").children.forEach((x, i) => {
        this.parsed_content.push({text: x.outerHTML, comment: this.object.content.comments.find(x => x.index === i)})
      })

    })

  },
  computed: {},
  methods: {
    getChatId(_id) {
      this.chat_id = _id
    },
    addComment(_index, element) {
      let data = element.comment;
      data.content_file = this.object.content.id;
      data.index = _index;
      if (!data["id"]) ChatApi.add_message(this.chat_id, {
        text: element.comment.text,
        blockquote: element.text.replace(/(<([^>]+)>)/gi, "")
      })
      DictionaryApi.add_comment(this.$route.params.id, data).then(resp => element.comment = resp)
    },
    deleteComment(element) {
      if (element.comment.id) DictionaryApi.delete_comment(this.$route.params.id, {id: element.comment.id}).then(resp => {
        element.comment = null;
      }).catch((err) => {
        this.$notify({
          type: 'error',
          text: "Действие запрещено"
        })
      });
      else element.comment = null;
    }
  },

}

</script>
<style lang="scss">
.content-area {
  display: flex;
  gap: 20px;

  .content-wrapper {
    display: flex;
    gap: 50px;
  }

  .chat {
    flex: 1 1 auto;
    width: 30%;
  }

  .content-body {
    width: 70%;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 10px;
    display: block;
    position: relative;

    .element {
      display: flex;
      justify-content: space-between;
      position: relative;
      cursor: pointer;

      .date {
        font-size: 10px;
        color: #333;
        position: absolute;
        left: 0;
        top: 0;
        height: auto;
        margin: 0;
      }

      .close {
        font-weight: bold;
        color: #000;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
      }

      &.focus {
        z-index: 3;
      }

      .text {
        margin-right: 15px;
      }

      .add-comment {
        flex: 0 0 auto;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50px;
        width: 32px;
        height: max-content;
        display: none;
      }

      &:hover, &.hover {
        background: rgba(213, 154, 0, 0.2);

        .add-comment {
          display: block;
        }
      }

      .comment {
        position: absolute;
        right: -220px;
        border: 3px solid #666;
        border-radius: 10px;
        width: 200px;
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        background: #fff;
        z-index: 2;

        textarea {
          width: 100%;
          max-width: 100%;
          min-width: 100%;
          height: 89px;
        }
      }

    }

  }

  img {
    display: block;
    width: 100% !important;
  }
}
</style>