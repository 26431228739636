<template>
  <section class="bg-white">
    <div >
      <a :href="$store.getters.current_domain">Главная</a> / <span>Соглашение об обработке персональных данных</span>
      <h1 class="mt-3">Соглашение об обработке персональных данных</h1>
      <div class="breadcrumb">

      </div>
      <div class="row">
        <div id="content-2">
          <p><b>1. Термины и определения</b><br>
            <br>
            В настоящем соглашении об обработке персональных данных (далее – «Соглашение») нижеприведенные термины имеют
            следующие определения:<br>
            Оператор – общество с ограниченной ответственностью «{{ current.name }}», ОГРН {{current.ogrn}}, осуществляющее
            руководство интернет-сайтом {{ current.site }} на условиях настоящего Соглашения.<br>
            Акцепт Соглашения – полное и безоговорочное принятие всех условий Соглашения путем осуществления действий по
            Регистрации на Сайте.<br>
            Персональные данные – информация, внесенная Пользователем (субъектом персональных данных) на Сайт и
            относящаяся
            прямо или косвенно к данному Пользователю.<br>
            Пользователь – любое лицо, успешно прошедшее процедуру Регистрации на Сайте.<br>
            Регистрация — процедура внесения Пользователем в базу зарегистрированных пользователей Сайта своих
            персональных
            данных, производимая с целью идентификации Пользователя. По результатам Регистрации создаётся личный кабинет
            Пользователя на Сайте. Регистрация является добровольной. <br>
            Сайт – интернет-сайт, размещенный в сети Интернет по адресу: {{ current.site }}, руководство которым
            осуществляет Оператор. <br>
            <br>
            <b>2. Общие положения</b><br>
            <br>
            2.1. Настоящее Соглашение составлено на основании требований Федерального закона от 27.07.2006 г. № 152-ФЗ
            «О
            персональных данных» и действует в отношении всех персональных данных, которые Оператор может получить о
            Пользователе во время использования им Сайта.<br>
            2.2. Регистрация Пользователя на Сайте означает безоговорочное согласие Пользователя со всеми условиями
            настоящего
            Соглашения (Акцепт Соглашения). В случае несогласия с этими условиями Пользователь не осуществляет
            Регистрацию на
            Сайте.<br>
            2.3. Согласие Пользователя на предоставление персональных данных Оператору и их обработку Оператором
            действует до
            момента прекращения деятельности Оператора либо до момента отзыва согласия Пользователем. Акцептовав
            настоящее
            Соглашение и пройдя процедуру Регистрации, Пользователь подтверждает, что он, действуя своей волей и в своем
            интересе, передает свои персональные данные для обработки Оператору и согласен на их обработку. Пользователь
            уведомлен, что обработка его персональных данных будет осуществляться Оператором на основании Федерального
            закона
            от 27.07.2006 г. № 152-ФЗ «О персональных данных».<br>
            <br>
            <b>3. Перечень персональных данных и иной информации о пользователе, подлежащих передаче Оператору</b><br>
            <br>
            3.1. При использовании Сайта Оператора Пользователем представляются следующие персональные данные:<br>
            3.1.1. Достоверная персональная информация, которую Пользователь предоставляет о себе самостоятельно при
            Регистрации и/или в процессе использования сервисов Сайта, включая, фамилию, имя, отчество, дату рождения,
            место
            рождения, номер телефона (домашний или мобильный), адрес электронной почты, адрес страницы в социальных
            сетях
            (Твиттер, ВКонтакте и т.д.).<br>
            3.1.2. Данные, которые автоматически передаются сервисам Сайта в процессе их использования с помощью
            установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из
            Cookies,
            информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к сервисам).<br>
            3.2. Оператор не проверяет достоверность персональных данных, предоставляемых Пользователем. При этом
            Оператор
            исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию по
            вопросам,
            предлагаемым в форме Регистрации.<br>
            <br>
            <b>4. Цели, правила сбора и использования персональных данных</b><br>
            <br>
            4.1. Оператор осуществляет обработку персональных данных, которые необходимы для предоставления сервисов
            Пользователю.<br>
            4.2. Персональные данные Пользователя используются Оператором в следующих целях:<br>
            4.2.1. Идентификация Пользователя;<br>
            4.2.2. Предоставление Пользователю персонализированных сервисов Сайта.<br>
            4.3. В ходе обработки персональных данных будут совершены следующие действия: сбор, запись, систематизация,
            накопление, хранение, уточнение (обновление, изменение), извлечение, использование, блокирование, удаление,
            уничтожение.<br>
            4.4. Пользователь соглашается с тем, что указанные им сведения в определенных случаях могут предоставляться
            уполномоченным государственным органам РФ в соответствии с действующим законодательством РФ.<br>
            4.5. Персональные данные Пользователя хранятся и обрабатываются Оператором в порядке, предусмотренном
            настоящим
            Соглашением, в течение всего срока осуществления деятельности Оператором.<br>
            4.6. Обработка персональных данных осуществляется Оператором путем ведения баз данных, автоматизированным,
            механическим, ручным способами.<br>
            4.7. Сайт использует файлы Cookies и другие технологии, чтобы отслеживать использование сервисов Сайта. Эти
            данные
            необходимы для оптимизации технической работы Сайта и повышения качества сервисов Сайта. На Сайте
            автоматически
            записываются сведения (включая URL, IP-адрес, тип браузера, язык, дату и время запроса) о каждом посетителе
            Сайта.
            Пользователь вправе отказаться от предоставления персональных данных при посещении Сайта или отключить файлы
            Cookies, но в этом случае не все функции и сервисы в составе Сайта могут работать правильно.<br>
            4.8. Предусмотренные настоящим Соглашением условия конфиденциальности распространяются на всю информацию,
            которую
            Оператор может получить о Пользователе во время пребывания последнего на Сайте и использования Сайта.<br>
            4.9. Не является конфиденциальной информация, публично раскрытая в ходе исполнения настоящего Соглашения, а
            также
            информация, которая может быть получена сторонами или третьими лицами из источников, к которым имеется
            свободный
            доступ любым лицам.<br>
            4.10. Оператор принимает все необходимые меры для защиты конфиденциальности персональных данных Пользователя
            от
            несанкционированного доступа, изменения, раскрытия или уничтожения.<br>
            4.13. Оператор никогда не направляет Пользователю электронных запросов с просьбой указать, подтвердить или
            каким-либо иным образом сообщить Оператору указанный Пользователем при регистрации пароль. Пароль хранится
            на
            Сайте в зашифрованном виде.<br>
            4.14. В случае утери Пользователем аутентификационных данных для доступа на Сайт, или при необходимости
            изменить
            адрес электронной почты, указанный Пользователем при Регистрации, повторное сообщение Пользователю утерянных
            данных и/или смена адреса электронной почты могут быть осуществлены лично Пользователем с использованием
            сервисов
            Сайта. Сообщение Пользователю утерянных данных осуществляется по адресу электронной почты, указанному
            Пользователем при Регистрации.<br>
            4.15. В отношении персональных данных Пользователя сохраняется их конфиденциальность, кроме случаев
            добровольного
            предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.<br>
            4.16. Передача Оператором персональных данных Пользователя правомерна при реорганизации Оператора и передачи
            прав
            правопреемнику Оператора, при этом к правопреемнику переходят все обязательства по соблюдению условий
            настоящего
            Соглашения применительно к полученной им персональной информации.<br>
            <br>
            <b>5. Права пользователя как субъекта персональных данных</b><br>
            <br>
            5.1. Пользователь вправе:<br>
            5.1.2. Требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае,
            если
            персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются
            необходимыми
            для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.<br>
            5.1.3. Получать информацию, касающуюся обработки его персональных данных, в том числе содержащей:<br>
            5.1.3.1. подтверждение факта обработки персональных данных Оператором;<br>
            5.1.3.2. цели и применяемые оператором способы обработки персональных данных;<br>
            5.1.3.3. наименование и место нахождения Оператора;<br>
            5.1.3.4. обрабатываемые персональные данные, относящиеся к соответствующему субъекту персональных данных,
            источник
            их получения, если иной порядок представления таких данных не предусмотрен федеральным законом;<br>
            5.1.3.5. сроки обработки персональных данных, в том числе сроки их хранения;<br>
            5.1.3.6. иные сведения, предусмотренные действующим законодательством РФ.<br>
            5.2. Отзыв согласия на обработку персональных данных может быть осуществлен Пользователем путем направления
            Оператору соответствующего письменного (распечатанного на материальном носителе и подписанного
            Пользователем)
            уведомления.<br></p>

        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {

  name: 'UserAgreement',
  data() {
    return {
      settings: {
        "account.ton-reputation.com": {
          name: "Диджитал Софт",
          ogrn: "1207700215425",
          site: "https://ton-reputation.com/"
        },
        "account.orion-solutions.ru": {
          name: "Орион Солюшенс",
          ogrn: "1247700112472",
          site: "https://orion-solutions.ru/"
        }
      }
    }
  },
  computed: {
    current(){
      return this.settings[location.hostname]||this.settings[Object.keys(this.settings)[0]]
    }
  }
}
</script>