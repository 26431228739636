<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">
        <template v-if="project.id">Редактировать</template>
        <template v-else>Добавить</template>
        проект
        <template v-if="project.name">: {{ project.name }}</template>
      </h1>
      <BackButton></BackButton>
    </div>

    <form @submit.prevent="saveForm">
      <div class="row g-3">
        <div class="col-sm-12">
          <div>
            <label for="name" class="form-label mt-3">Название</label>
            <input id="name" class="form-control" v-model.trim="project.name">
          </div>

        </div>
      </div>
      <div class="row g-3">
        <div class="col-sm-12">
          <template v-if="user.user_type === 'free'">
            <div class="mt-2">
              <label for="name" class="form-label mt-3">Страны</label>
              <span class="form-group__message d-inline" v-if="project.country_error">{{ project.country_error }}</span>

              <multi_select v-bind:model.sync="project.countries" v-bind:model_error.sync="project.country_error"
                            v-bind:options="countries" v-bind:multi="true" v-bind:max="5"></multi_select>
            </div>
            <div class="mt-2" v-if="project.countries.length">

              <div v-for="country in project_countries" class="card country">
                <div class="card-body">
                  <strong class="card-title no-wrap">{{ country.name }} <sup>{{ country.code }}</sup></strong>

                  <div class="d-flex">
                    <div class="form-check me-2">
                      <input class="form-check-input" type="checkbox" v-model="country.yandex">
                      <label class="form-check-label">
                        Y
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="country.google">
                      <label class="form-check-label">
                        G
                      </label>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <label class="mt-2">Ключевые слова </label>
            <popper trigger="hover" :options="{ placement: 'right',modifiers: { offset: { offset: '0,10px' } } }">
               <span slot="reference">
                   <i :class="`bi bi-info-circle link`"></i>
                </span>
              <template #content>
              <div class="popper text-left">
                Не должно быть дублей, регистр значения не имеет, нельзя использовать символы !?:*()@$# - только цифры,
                алфавит русский и английский и "-".
              </div>
              </template>

            </popper>

            <div class="row mt-2" v-for="(keyword, index) in project.keywords">
              <div :class="'col-6 ' + (keyword.error?'form-group--error':'')">
                <input class="form-control" :disabled="!keyword.can_edit" v-on:change="checkKeywordError"
                       v-model="keyword.name" type="text">
                <div class="form-group__message">
                  <div class="error" v-for="error in keyword.errors">- {{ error }}</div>
                </div>

              </div>
              <div class="col-3">
                <multi_select v-bind:model.sync="keyword.countries" v-bind:name="'Страны'"
                              v-bind:options="project_countries" v-bind:multi="true" v-bind:max="5"></multi_select>
              </div>
              <div class="col-sm-3 pt-1">

                <button type="button" @click="delete_key=keyword; $modal('show', 'delete_object')" v-if="keyword.id"
                        class="btn btn-danger col-2 btn-sm w-sm">
                  <i class="bi bi-x-circle-fill"></i>
                </button>
                <button type="button" v-else-if="index" class="btn btn-danger col-2 btn-sm w-sm"
                        @click="project.keywords.splice(index, 1)">
                  <i class="bi bi-dash"></i>
                </button>
                <button type="button" v-if="index === project.keywords.length - 1 && avail_count > 0"
                        class="btn btn-success col-2 btn-sm w-sm"
                        @click="project.keywords.push(JSON.parse(JSON.stringify(empty_key)))">
                  <i class="bi bi-plus-lg"></i>
                </button>

              </div>
            </div>
          </template>
          <div class="mb-3 col-7">
            <label for="negative" class="form-label mt-3">Негативные публикации на отслеживание</label>
            <input class="form-control" accept=".xlsx" @change="saveFileDict($refs.negative_file, 'negative')"
                   ref="negative_file" type="file"
                   id="negative">

            <a class="fst-italic" href="https://804303.selcdn.ru/ra_media/media/static/temp/negative_links.xlsx"><i
                class="bi bi-question"></i> пример файла</a>
          </div>
        </div>
      </div>

      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <button class="btn btn-primary btn-sm m-2" type="submit" v-if="$store.getters.tariff.can_edit">Сохранить
        </button>
      </div>
    </form>
    <modal modal_id="delete_object">
      <template v-slot:header>
Удалить "{{ delete_key.name }}"?
      </template>
      <template v-slot:body>
        <div class="modal-body d-flex">

          <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
          <p> Вместе с ключевым словом удалятся результаты выдачи</p>
        </div></template>
      <template v-slot:footer>
        <button type="button" class="btn btn-secondary" @click="$modal('hide', 'delete_object');"
                  data-bs-dismiss="modal">Отмена
          </button>
          <button type="button" class="btn btn-danger" v-on:click="DeleteKeyword(delete_key)">Удалить</button>

      </template>

    </modal>
    <modal modal_id="has_change">
      <template v-slot:header>

      </template>
      <template v-slot:body>
        <div class="modal-body d-flex">

          <h1 class="text-danger"><i class="bi bi-exclamation-lg"></i></h1>
          <p> На странице есть не изменённые данные. Покинуть страницу?</p>
        </div>
      </template>
      <template v-slot:footer>
         <button type="button" class="btn btn-secondary" @click="$modal('hide', 'has_change');"
                  data-bs-dismiss="modal">Отмена
          </button>
          <button type="button" class="btn btn-danger" v-on:click="$router.push(nxt.path)">Покинуть</button>

      </template>


    </modal>
    <modal modal_id="success_save">
      <template v-slot:header>

      </template>
      <template v-slot:body>
        <p> Проект успешно сохранён</p>
          <div v-if="save_errors.length">
            <small><strong>При сохранении произошли следующие ошибки:</strong></small>
            <ul class="form-group__message">
              <li v-for="error in save_errors">{{ error }}</li>
            </ul>
          </div>
      </template>
      <template v-slot:footer>
         <button type="button" class="btn btn-secondary" @click="$modal('hide', 'success_save');"
                  data-bs-dismiss="modal">Продолжить редактирование
          </button>
          <button type="button" class="btn btn-secondary" v-on:click="$router.push(`/projects/${project.id}`)">Перейти к
            просмотру
          </button>
      </template>

    </modal>


  </main>
</template>

<script>
import MultiSelect from "@/components/plugins/Select.vue";
import {ProjectApi} from "@/api/project";

export default {
  name: 'ProjectAdd',
  components: {
    'multi_select': MultiSelect
  },
  data() {
    return {
      save_errors: [],
      save_process: false,
      countries: [],
      project: {
        name: '',
        keywords: [],
        countries: [],
        negative: ''
      },
      empty_key: {name: '', id: '', can_edit: true, countries: []},
      delete_key: {name: ''},
      nxt: false
    }
  },
  computed: {
    tariff() {
      return this.$store.getters.tariff;
    },
    user() {
      return this.$store.getters.user;
    },
    avail_count() {
      return this.tariff.request_count - (this.user.keyword_count + this.project.keywords.filter(x => !x.id).length);
    },
    project_countries() {
      let qs = this.project.countries, $this = this;
      qs.forEach(function (country) {
        let index = $this.project.countries.indexOf(country);
        if (!country.id) if (country.code === 'RU') {
          country.yandex = true;
          country.google = true;
        } else country.google = true;
        $this.project.countries[index] = country
      })
      return qs
    }
  },
  watch: {
    avail_count(val) {
      if (val && !this.project.keywords.length) this.setEmptyData();
    },
  },

  mounted: function () {
    let $this = this;

    if (this.avail_count && !this.project.keywords.length) this.setEmptyData();
    if ($this.$route.params.id) {
      ProjectApi.get_for_edit($this.$route.params.id).then(response => {
        $this.project = response;
        if ($this.avail_count && !$this.project.keywords.length) $this.setEmptyData();
        $this.saved = JSON.stringify($this.project);
        $this.getCountries()
      })
    } else {
      $this.saved = JSON.stringify($this.project);
      $this.getCountries()
    }
  },
  methods: {

    getCountries() {
      let $this = this;
      ProjectApi.countries().then(response => {
        $this.countries = response.results;
        if (!$this.project.countries.length) {
          $this.project.countries = $this.project.countries.concat($this.countries.filter(x => x.code === 'RU'))
        }
      });
    },

    saveFileDict(ref, name) {
      this.project.name = ref.files[0]
    },
    setEmptyData() {
      let $this = this;
      if (this.avail_count)$this.project.keywords.push(JSON.parse(JSON.stringify($this.empty_key)))

      // Array(this.avail_count >= 0 ? 1 : 0).forEach(() => {
      //   $this.project.keywords.push(JSON.parse(JSON.stringify($this.empty_key)))
      // })
    },
    checkKeywordError() {
      let error = false, $this = this;
      this.project.keywords.forEach(function (model) {
        let key_error = false, errors = [];
        if (model.name && $this.project.keywords.filter(x => x.name.toLowerCase().trim() === model.name.toLowerCase().trim()).length > 1) {
          key_error = true;
          errors.push('Дублирующиеся запросы')
        }
        if (model.id && !model.name) {
          key_error = true;
          errors.push('Введите запрос')
        }
        // if (!/^\w+|[\u0400-\u04FF, ]|-+$/.test(model.name) && model.name){
        //   key_error = true;
        //   errors.push('Ключевое слово должно состоять из букв и цифр')
        // }
        error = key_error ? key_error : error;
        model.error = key_error;
        model.errors = errors
      })
      return error;
    },
    saveForm: function () {
      let $this = this;
      if (!this.checkKeywordError()) {
        this.save_process = true;
        let formData = new FormData();
        for (let i in $this.project) {
          if (i === 'keywords') formData.set(i, JSON.stringify($this.project[i]));
          else if (i === 'countries' || (typeof $this.project[i] === 'object' && i !== 'negative')) formData.set(i, JSON.stringify($this.project[i]));
          else formData.set(i, $this.project[i])
        }

        ProjectApi.update(formData).then(response => {
          $this.save_errors = response.errors;
          delete response.error;
          $this.$modal('show','success_save');
          $this.$notify({
            type: 'success ',
            text: `Проект ${$this.project.id ? 'сохранён' : 'добавлен'}`
          });
          $this.project = response;
          $this.saved = JSON.stringify($this.project);
          if (!$this.$route.params.id) {
            $this.$router.push(`/projects/${response.id}/edit/`)
          } else $this.save_process = false;

          this.$store.dispatch('updateObject', response);
          this.$store.dispatch('get_user');
        }).catch(() => {
          this.$notify({
            type: 'error',
            text: 'Проект не удалось создать. Информация об ошибке отравлена в поддержку. Проблема будет решена в ближайшее время'
            // text: err.response.data
          });
          $this.save_process = false;

        });
      }

    },

    DeleteKeyword(keyword) {
      let $this = this;
      ProjectApi.delete_keyword($this.project.id, keyword.id).then(response => {

        $this.$modal( 'hide','delete_object');
        $this.project.keywords.splice($this.project.keywords.indexOf(keyword), 1);
        if (!$this.project.keywords.length) $this.project.keywords.push(JSON.parse(JSON.stringify($this.empty_key)));
        $this.$notify({
          type: 'success ',
          text: response
        });
        $this.$store.dispatch('get_user');
      }).catch(err => {
        $this.$notify({
          type: 'error ',
          text: err.response.data
        });
      });

    }
  },
  beforeRouteLeave(to, from, next) {
    if (JSON.stringify(this.project) !== this.saved && !this.nxt) {
      this.nxt = to;
      this.$modal('show', 'has_change');
    } else next()
  }

}

</script>
<style scoped>
.card.country {
  width: 11rem;
  display: inline-block;
}

label {
  font-weight: 700;
}

.check_key .form-check {
  margin: -5px 0 0;
}
</style>
