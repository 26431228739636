<template>

   <div :class="`card border border-${status}`" v-if="data">
    <div class="card-body">
      <h4 :class="`card-title text-${status}`">
        {{ status == "danger" ? "Нецелевая информация" : "Целевая информация" }}</h4>
      <template v-for="state in $STATES">
        <template v-if="data['_'+state.id]||data['_'+state.id]==0">
          <div class="row" v-if="(state.id<50 || (state.id>=50&&show_more))">
            <div class="col p-1">
              <div class="">
                <div class="card-body ">
                  <h6 class="card-title"><b>Всего в {{ state.name }}</b></h6>
                  <hr>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Общий:</p>
                    <p class="place">
                        <b :class="`text-${status}`">{{ data['_' + state.id] }}</b>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_y'] }}</b>
                    </p>
                    <p class="place">
                      <i class="google"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_g'] }}</b>
                    </p>
                  </div>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Уникальный:</p>
                    <p class="place">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_uniq'] }}</b>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_uniq_y'] }}</b>
                    </p>
                    <p class="place"><i class="google"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_uniq_g'] }}</b>
                    </p>
                  </div>

                </div>
              </div>
            </div>
            <div class="col p-1">
              <div class="">
                <div class="card-body ">
                  <h6 class="card-title"><b>
                    <template v-if="status=='danger'">По договору</template>
                    <template v-else>Размещенные публикации</template>
                    в {{ state.name }}</b></h6>
                  <hr>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Общий:</p>
                    <p class="place">
                        <b :class="`text-${status} me-2`">{{ data['_' + state.id + '_offer'] }}</b>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_y'] }}</b>
                    </p>
                    <p class="place"><i class="google"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_g'] }}</b>
                    </p>


                  </div>
                  <div class="d-flex no-wrap">
                    <p class="text-muted head">Уникальный:</p>
                    <p class="place">
                        <b
                            :class="`text-${status} me-2`">{{ data['_' + state.id + '_offer_uniq'] }}</b>
                    </p>
                    <p class="place">
                      <i class="yandex"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_uniq_y'] }}</b>
                    </p>
                    <p class="place"><i class="google"></i>
                        <b :class="`text-${status}`">{{ data['_' + state.id + '_offer_uniq_g'] }}</b>
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>
      </template>

    </div>
  </div>

</template>

<script>

  export default {
    name: 'InfoBlock',
    components: {},

    props: ["data", "status", "project_id", 'date', 'images', 'show_more', 'country_code'],
    data() {
      return {}
    },

    mounted: function () {

    },
    methods: {
      getLinkTone: function () {
//        if (col === 1)
          if (this.status === 'danger') return ['negative'];
          else return ['positive'];
//        else {
//          if (this.status === 'danger') return ['negative', 'negative_offer'];
//          else return ['positive', 'positive_offer']
//        }
      },
      getTypeTone: function (col) {
        if (col === 1)
          if (this.status === 'danger') return ['offer'];
          else return ['our'];
        else {
          if (this.status === 'danger') return ['offer', 'new'];
          else return ['start', 'our', 'new', 'finish', 'replace']
        }
      }

    }
  }

</script>
