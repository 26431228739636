<script setup>
import {defineProps, onMounted, ref, watch} from "vue";
import {useMutationObserver} from '@vueuse/core'

const props = defineProps({
  modal_id: String,
});
const modal_id = props.modal_id;
const modalArea = ref()
let show = ref(false);
useMutationObserver(modalArea,
    (mutations) => {
      if (mutations[0])
        show.value = modalArea.value.classList.contains("show")
    }, {
      attributes: true,
      attributeOldValue: true,
      attributeFilter: ['class'],
    })

watch(show, value => {
  if (value) window.addEventListener("keydown", closeOnEsc)
  else window.removeEventListener("keydown", closeOnEsc)
})

function closeOnEsc(evt) {
  let isEscape;
  if ("key" in evt) {
    isEscape = (evt.key === "Escape" || evt.key === "Esc");
  } else {
    isEscape = (evt.keyCode === 27);
  }
  if (isEscape) {
    closeModal(modal_id)
  }
}

function closeModal(modal_id) {
  document.getElementById(modal_id).classList.remove("show");
}

onMounted(() => {
  const modal = document.getElementById('inner' + modal_id);
  const modal_head = document.getElementById('header' + modal_id);
  const overlay = document.getElementById("overlay" + modal_id);
  overlay.addEventListener("click", (event) => {
    closeModal(modal_id)
  });

  modal_head.ondragstart = function (e) {
    return false;

  };

  function getCoords(elem) {
    let box = elem.getBoundingClientRect();
    return {
      top: box.top + scrollY,
      left: box.left + scrollX
    };
  }

  modal_head.onmousedown = function (e) {
    let coords = getCoords(modal);
    let shiftX = e.pageX - coords.left;
    let shiftY = e.pageY - coords.top;
    modal.style.position = 'absolute';

    function moveAt(e) {
      modal.style.left = e.pageX - shiftX + 'px';
      modal.style.top = e.pageY - shiftY + 'px';
    }

    document.onmousemove = function (e) {
      moveAt(e);
    }
    modal_head.onmouseup = function () {
      document.onmousemove = null;
      modal_head.onmouseup = null;
    }
  }


  const resizer = document.getElementById('resizer' + modal_id)
  resizer.addEventListener('mousedown', initDrag, false);

  let startX, startY, startWidth, startHeight;

  function initDrag(e) {
    startX = e.clientX;
    startY = e.clientY;
    startWidth = parseInt(document.defaultView.getComputedStyle(modal).width, 10);
    startHeight = parseInt(document.defaultView.getComputedStyle(modal).height, 10);
    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
    modal.style.pointerEvents = "none";
  }

  function doDrag(e) {
    modal.style.width = (startWidth + e.clientX - startX) + 'px';
    modal.style.height = (startHeight + e.clientY - startY) + 'px';
  }

  function stopDrag(e) {
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
    modal.style.pointerEvents = "all";
  }

})


</script>
<template>
  <!--  <transition name="modal-fade">-->
  <div class="modal-backdrop" ref="modalArea" :id="modal_id">
    <div class="modal-overlay" :id="'overlay'+modal_id"></div>
    <div class="modal" :id="'inner'+modal_id"
    >
      <header class="modal-header" :id="'header' + modal_id">
        <slot name="header">
        </slot>
        <button
            type="button"
            class="btn-close"
            @click="closeModal(modal_id)"
        >
          x
        </button>
      </header>

      <div class="modal-body">
        <slot name="body">
        </slot>
      </div>

      <footer class="modal-footer">
        <slot name="footer">
        </slot>
      </footer>
      <div class="resizer" :id="'resizer'+modal_id"></div>
    </div>
  </div>

  <!--  </transition>-->
</template>

<style>
.modal-backdrop {
  position: fixed;
  box-sizing: border-box;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000 !important;
  justify-content: center;
  align-items: center;
  display: flex;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s linear, opacity 0.3s linear;
}

.modal-backdrop.show {
  display: flex;
  visibility: visible;
  opacity: 1;
}

.modal-overlay {
  width: 100%;
  height: 100vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
  z-index: 0;
}

.modal {
  background: #FFFFFF;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  min-width: 600px;
  z-index: 1;
  justify-content: start;
  position: relative;
  max-width: 80vw;
}

.modal-header,
.modal-footer {
  padding: 15px;
  display: flex;
}

.modal-header {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  justify-content: space-between;
  font-weight: bold;
  max-height: 70px;
  cursor: move;
  background: repeating-linear-gradient(
      45deg,
      #fff,
      #fff 10px,
      #fbf1f1 10px,
      #fbf1f1 20px
  );
}

.warning .modal-header {
  background: repeating-linear-gradient(45deg, #fff, #fff 10px, #ef555582 10px, #ef555582 20px);
}

.modal-footer {
  border-top: 1px solid #eeeeee;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
  margin-top: auto;
}

.modal-body {
  position: relative;
  padding: 20px 10px;
  height: auto !important;
  max-height: 90vh;
  overflow-y: scroll;
  flex: 1 1 auto;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  background: transparent;
}

.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity .5s ease;
}

.resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 20px 20px;
  border-color: transparent transparent #a6a5a5 transparent;
  transform: rotate(0deg);
}

label {
  align-items: center;
  gap: 5px;
}

</style>