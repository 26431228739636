import {createRouter, createWebHistory} from 'vue-router'
//users
import Login from '@/views/user/Login.vue'
import Registartion from '@/views/user/Registartion.vue'
import RegistartionActivate from '@/views/user/RegistartionActivate.vue'
import Forgot from '@/views/user/Forgot.vue'
import SetPassword from '@/views/user/SetPassword.vue'
import Settings from '@/views/user/Settings.vue'
// project
import ProjectAdd from '@/views/project/ProjectAdd.vue'
import ProjectSnapDetail from '@/views/project/ProjectSnapDetail.vue'
import ProjectDetail from '@/views/project/ProjectDetail.vue'
import ProjectForecast from '@/views/project/forecast/ProjectForecast.vue'
import ProjectReports from "@/views/project/ProjectReports.vue";
//links
import LinksInfo from '@/views/links/LinksInfo.vue'

//dictionary
import Comments from '@/views/dictionary/Comments.vue'
import ContentService from '@/views/dictionary/contents/ContentsGenerate.vue'
import ContentDetail from '@/views/dictionary/contents/ContentDetail.vue'
import Extraction from '@/views/dictionary/Extraction.vue'
import MassMedia from '@/views/dictionary/MassMedia.vue'
import Influence from '@/views/dictionary/Influence.vue'
import Monitoring from '@/views/dictionary/Monitoring.vue'
import Psychotypes from '@/views/dictionary/Psychotypes.vue'
import Responses from '@/views/dictionary/Responses.vue'
import Seo from '@/views/dictionary/Seo.vue'
import Socials from '@/views/dictionary/Socials.vue'
import TonalityService from '@/views/dictionary/TonalityService.vue'
import Wikipedia from '@/views/dictionary/wikipedia/Wikipedia.vue'
import Targets from '@/views/dictionary/Targets.vue'


import UserAgreement from '@/views/UserAgreement'
import Front from '@/views/Front.vue'
import PageNotFound from '@/views/PageNotFound.vue'
import store from '@/store/index'


const router = new createRouter({
    history: createWebHistory("/cabinet"),
    routes: [
        {
            path: '/user-agreement',
            name: 'user_agreement',
            component: UserAgreement,
            meta: {
                notAuth: true,
                title: "Соглашение об обработке персональных данных"
            }
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                notAuth: true
            }
        },
        {
            path: '/registration',
            name: 'registration',
            component: Registartion,

            meta: {
                notAuth: true
            }
        },
        {
            path: '/registration/:activate_code',
            name: 'registration_activate',
            component: RegistartionActivate,
            meta: {
                notAuth: true
            }
        },
        {
            path: '/forgot_password',
            name: 'forgot',
            component: Forgot,
            meta: {
                notAuth: true
            }
        },
        {
            path: '/set_password/:uid/:token',
            name: 'set_password',
            component: SetPassword,
            meta: {
                notAuth: true
            }
        },


        {
            path: '/',
            name: 'base',
            component: Front,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings',
            name: 'settings',
            component: Settings,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/projects/add',
            name: 'add_project',
            component: ProjectAdd,
            meta: {
                requiresAuth: true,
                requiresCP: true,
                userType: 'free',
            }
        },
        {
            path: '/projects/:id/edit',
            name: 'project_edit',
            component: ProjectAdd,
            meta: {
                requiresAuth: true,
                requiresCP: true,
                userType: 'free',

            }
        },
        {
            path: '/projects/:id/info',
            name: 'links_info',
            component: LinksInfo,
            meta: {
                requiresAuth: true,
                requiresCP: true
            }
        },
        {
            path: '/projects/:id',
            name: 'project_detail',
            component: ProjectDetail,
            meta: {
                requiresAuth: true,
                requiresCP: true
            }
        },
        {
            path: '/projects/:id/reports',
            name: 'project_report',
            component: ProjectReports,
            meta: {
                requiresAuth: true,
                requiresCP: true
            }
        },
        {
            path: '/projects/:id/snapshot',
            name: 'project_snap_detail',
            component: ProjectSnapDetail,
            meta: {
                requiresAuth: true,
                requiresCP: true
            }
        },
        {
            path: '/projects/:id/forecast',
            name: 'project_forecast',
            component: ProjectForecast,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/comments',
            name: 'comments',
            component: Comments,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/contents',
            name: 'contents',
            component: ContentService,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/contents/:id',
            name: 'content_detail',
            component: ContentDetail,
            meta: {
                requiresAuth: true,
            }
        },
        {
            path: '/extraction',
            name: 'extraction',
            component: Extraction,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/massmedia',
            name: 'massmedia',
            component: MassMedia,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/agents',
            name: 'agents',
            component: Influence,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/psychotype',
            name: 'psychotype',
            component: Psychotypes,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/reviews',
            name: 'reviews',
            component: Responses,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/seo',
            name: 'seo',
            component: Seo,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/monitoring',
            name: 'monitoring',
            component: Monitoring,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/socials',
            name: 'socials',
            component: Socials,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/tonality',
            name: 'tonality',
            component: TonalityService,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/wikipedia',
            name: 'wikipedia',
            component: Wikipedia,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },
        {
            path: '/targets',
            name: 'targets',
            component: Targets,
            meta: {
                requiresAuth: true,
                requiresRule: true
            }
        },

        {
            path: "/404", name: '404',
            component: PageNotFound,
            meta: {
                title: 'Страница не найдена',
                notAuth: true
            }
        },
        {
            path: "/:pathMatch(.*)",
            name: 'PageNotFound',
            component: PageNotFound,
            notAuth: true
        }
    ],
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
});

export default router

router.beforeEach((to, from, next) => {
    document.title = to.meta.title || store.getters.company.name;
    next();
});
router.beforeEach((to, from, next) => {
    store.commit('set_route', to);
    store.dispatch('init', (!to.meta.notAuth)).then(() => {

        if (to.matched.some(record => record.meta.requiresAuth)) {
            if (store.getters.isLoggedIn) {
                if (to.matched.some(record => record.meta.requiresRule)) {
                    if (store.getters.pages.filter((page) => {
                        return to.name === page.slug
                    }).length) {
                        next();
                        return

                    }
                    next('PageNotFound');
                    return;
                } else if (to.matched.some(record => record.meta.requiresCP)) {
                    if (store.getters.has_cp) {
                        if (to.matched.some(record => record.meta.userType)) {
                            if (store.getters.user_type === 'free') {
                                next();
                                return
                            }
                            next('PageNotFound');
                            return;
                        } else {
                            next();
                            return
                        }
                    }
                    next('PageNotFound');
                    return;
                } else {
                    next();
                    return
                }
            }
            location.pathname = '/cabinet/login/'
        } else if (to.matched.some(record => record.meta.notAuth)) {
            if (store.getters.isLoggedIn) {
                location.pathname = '/cabinet/';
            } else {
                next();
                return
            }

        } else {
            next()
        }
    }, error => {
        console.log(error)
    }).catch((e) => console.error(e))

});
