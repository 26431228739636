import {HTTP} from './common'

export const DictionaryApi = {
  save(name, object, id) {
    if (!id) return HTTP.post(`dictionary/${name}/`, object).then(response => {
      return response.data
    });
    else return HTTP.put(`dictionary/${name}/${id}/`, object).then(response => {
      return response.data
    })
  },
  update(name, id, data) {
    return HTTP.patch(`dictionary/${name}/${id}/`, data).then(response => {
      return response.data
    })
  },
  get_list(name, page, size, query) {
    return HTTP.get(`dictionary/${name}/?page=${page}&size=${size}&${query}`).then(response => {
      return response.data
    });
  },
  get(name, id) {
    return HTTP.get(`dictionary/${name}/${id}/`).then(response => {
      return response.data
    });
  },
  get_detail(name, id) {
    return HTTP.get(`dictionary/${name}/${id}/get_detail`).then(response => {
      return response.data
    });
  },
  get_changes(name, id, date, date_compare) {
    return HTTP.get(`dictionary/${name}/${id}/get_changes/?date=${date||""}&date_compare=${date_compare||""}`).then(response => {
      return response.data
    });
  },
  get_dict(name, dicts, choices) {
    return HTTP.get(`dictionary/${name}/get_dictionary/?dict=${dicts}&choices=${choices}`).then(response => {
      return response.data
    });
  },
  post_content(name, post_id) {
    return HTTP.get(`dictionary/${name}/${post_id}/post_content/`).then(response => {
      return response.data
    });
  },
  send_publication(name, id) {
    return HTTP.get(`dictionary/${name}/${id}/send/`).then(response => {
      return response.data
    });
  },
   load_file (name, object) {
    return HTTP.post(`dictionary/${name}/load_file/`, object).then(response => {
      return response.data
    })
  },
   generate_text (id) {
    return HTTP.get(`dictionary/contents/${id}/generate_text/`).then(response => {
      return response.data
    })
  },
   add_comment (id, data) {
    return HTTP.post(`dictionary/contents/${id}/add_comment/`, data).then(response => {
      return response.data
    })
  },
   delete_comment (id, data) {
    return HTTP.post(`dictionary/contents/${id}/delete_comment/`, data).then(response => {
      return response.data
    })
  },
   recreate_archive (id) {
    return HTTP.get(`dictionary/contents/${id}/recreate_archive/`).then(response => {
      return response.data
    })
  },
   upload_file_redactor (data) {
    return HTTP.post(`/summernote/upload_attachment/`, data).then(response => {
      return response.data
    })
  },


};

