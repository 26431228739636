<template>
  <div class="links">
  <table class="table dict">
    <thead>
    <tr>
      <th scope="col"> №</th>
      <th scope="col">Текст</th>
      <th scope="col">Дата</th>
      <th scope="col">Планируемая дата размещения</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr class="empty"></tr>
    <template v-for="(object, index) in objects">
      <tr :class="object.edit?'edit':''">
        <td>{{ index + 1 }}</td>
        <template v-if="object.edit">

          <td>
            <div :class="object.text_error?'form-group--error':''">
              <label class="d-none">Текст к размещению</label>
              <textarea class="form-control" name="" cols="50" rows="15"
                        v-on:keyup="object.text_error = false"
                        v-model="object.text"></textarea>
            </div>
            <div class="error" v-if="object.text_error">{{ object.text_error }}</div>
          </td>

        </template>
        <template v-else>
          <td class="text-area">
            {{ !object.text_trun ? object.text.slice(0, 500) : object.text }}
            <button v-if="object.text.length > 500" class="btn btn-sm text-primary"
                    @click="object.text_trun = !object.text_trun">
              <i :class="`bi bi-chevron-${object.text_trun?'up':'down'}`"></i></button>
          </td>
        </template>

        <td>{{ object.date }}</td>
        <td>{{ object.date_place }}</td>
        <td>
          <template v-if="!object.edit">
            <a href="" v-on:click.prevent="object.edit = true;"
               class="no-wrap"><i class="bi bi-pencil"></i>
              <small>редактировать</small>
            </a>

          </template>
          <template v-else>
            <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                class="bi bi-check-circle"></i>
              <small>Сохранить</small>
            </a> <br>
            <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                class="bi bi-x-circle"></i>
              <small>Отмена</small>
            </a>
          </template>
          <br>
        </td>

      </tr>
    </template>
    <tr v-if="!objects.length">
      <td colspan="5">
        Не добавлено ни одной записи
      </td>
    </tr>
    <tr>
      <td colspan="5">
        <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
      </td>
    </tr>
    </tbody>
  </table>
    </div>
</template>

<script>

import {DictionaryApi} from "@/api/dictionary";

export default {
  name: "WikiRequests",
  props: ['objects', 'wiki_id'],
  components: {},
  data() {
    return {
      empty: {text: '', edit: true},
      name: "wikipedia/request"
    }
  },
  methods: {
    addNote: function () {
      this.objects.push(JSON.parse(JSON.stringify(this.empty)));
    },
    endEdit(object) {
      let $this = this;
      if (object.id) {
        DictionaryApi.get(this.name, object.id).then(response => {
          $this.objects.splice($this.objects.indexOf(object), 1, response);
        });
      } else this.objects.splice(this.objects.indexOf(object), 1);
      if (this.edit_object) this.edit_object = null;
    },
    saveNote: function (object) {
      // let errors = this.validateData(object);
      if (!object.text) object.text_error = 'Обязательное поле';
      else {
        let $this = this;
        object.wiki = this.wiki_id
        DictionaryApi.save(this.name, object, object.id).then(response => {
          $this.objects.splice($this.objects.indexOf(object), 1, response);
          if ($this.edit_object) $this.edit_object = null;
          if (alert) $this.$notify({
            group: 'alert',
            type: 'success ',
            text: 'Запись сохранена'
          });
        });
      }

    },

  }
}
</script>
