import axios from 'axios'
import store from '../store/index'

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.baseURL = store.getters.api_path;
axios.defaults.withCredentials = true;

export const HTTP = axios.create({
    baseURL: store.getters.api_path,
    headers: {
        // Set your Authorization to 'JWT', not Bearer!!!
        Authorization: `JWT ${store.getters.jwt}`,
        "Content-Type": "application/json",
    },
    xhrFields: {
        withCredentials: true,
    },
});
HTTP.interceptors.request.use(function (config) {
    store.dispatch('loader', true);
    return config;
}, function (error) {
    store.dispatch('loader', false);
    return Promise.reject(error);
});
HTTP.interceptors.response.use(function (response) {
    store.dispatch('loader', false);
    return response;
}, function (error) {
    if (error.response.status === 404) {
        store.dispatch("go_404");
    }
    if (error.response.status === 403) {
        store.dispatch("go_403");
    }
    if (error.response.status === 401) store.dispatch('logout');
    store.dispatch('loader', false);
    return Promise.reject(error);
});
export default HTTP
