<template>
  <main class="dashboard">
       <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{current_page.name}}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body links">

        <div class="table-responsive">
            <div class="mb-3 filters">
              <label for="id_file" class="form-label mt-3">Загрузка ссылок, формат файла xlsx</label>
              <div class="d-flex">
                <div>

                  <input class="form-control" accept=".xlsx" @change="saveFile($refs.id_file)"
                         ref="id_file" type="file"
                         id="id_file">
                </div>
                <button v-on:click="sendForm()" class="btn btn-success">Загрузить</button>
              </div>
              <a class="fst-italic" href="https://804303.selcdn.ru/ra_media/media/static/temp/negative_links.xlsx"><i
                class="bi bi-question"></i> пример файла</a>
            </div>
          <table class="table">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Ссылка</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col"></th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr>
                <td>{{(index + 1) + ((page - 1) * size)}}</td>
                <td>{{object.link}}</td>
                <td>{{object.date}}</td>
                <td>
                  <button v-if="!object.extract_yandex" class="btn btn-sm btn-success" @click="object.extract_yandex=true; changeStatus(object)">Изъять Yandex</button>
                  <span v-else class="badge bg-secondary">отправлено на изъятие</span>
                </td>
                <td>
                  <button v-if="!object.extract_google" class="btn btn-sm btn-success" @click="object.extract_google=true; changeStatus(object)">Изъять Google</button>
                  <span v-else class="badge bg-secondary">отправлено на изъятие</span>
                </td>
              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="5">
                Не добавлено ни одной записи
              </td>
            </tr>

            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>

<modal name="success_save" class="modal-dialog" :adaptive="true" :height="'auto'" :max-width="600">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="btn-close" @click="$modal.hide('success_save');" data-bs-dismiss="modal"
                  aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <h4> Успешно загружено</h4>
          <div v-if="save_answer.length">
            <p v-for="error in save_answer">{{error}}</p>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$modal.hide('success_save');"
                  data-bs-dismiss="modal">Закрыть
          </button>
        </div>
      </div>
    </modal>
  </main>
</template>

<script>
  import {DictionaryApi} from '@/api/dictionary'
  import dataMixin from './mixins.js';
  export default {
    name: 'ExtractionService',
    mixins: [dataMixin],
    data() {
      return {
        name: 'extraction',
        save_answer: [],
        file: ''
      }
    },
    methods: {
      changeStatus(obj){
        this.submitNote(obj, false)
        this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Отправлено на изъятие'
        });
      },
       saveFile(ref) {
        this.file = ref.files[0];
      },
      sendForm: function () {
        let $this = this;
        if ($this.file) {
          let formData = new FormData();
          formData.set('file', $this.file);
          DictionaryApi.load_file($this.name, formData).then(response => {
            $this.save_answer = response.answer;
            $this.$modal.show('success_save');
            $this.file = '';
            $this.$refs.id_file.value = null;
            $this.getData()
          })
        }
        else{
          this.$notify({
              group: 'alert',
              type: 'error ',
              text: 'Для загрузки выберите файл'
            });
        }

      },
    }
  }

</script>


<style>
  textarea {
    min-width: 380px;
  }

  .form-select {
    min-width: 150px;
  }
</style>
