import {createApp} from 'vue'
import App from './App'
import router from './router'
import store from './store/index'
import Notifications from '@kyvg/vue3-notification'
import CustomVar from '@/plugins/custom'
import ModalCustom from '@/components/ModalCustom.vue'
import CountryChoice from '@/components/CountryChoice.vue'
import BackButton from '@/components/BackButton.vue'
import MultiSelect from '@/plugins/Select.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Popper from "vue3-popper";
import Vue2Editor from "vue3-editor";

import moment from 'moment';
moment.locale('ru');

import 'vue-final-modal/style.css'
// import './registerServiceWorker'



let app;
app = createApp(App);
app.use(router)
app.use(Notifications).use(Vue2Editor).use(store)
app.component("BackButton", BackButton)
app.component("modal", ModalCustom)
app.component("CountryChoice", CountryChoice)
app.component("MultiSelect", MultiSelect)
app.component('date-picker', VueDatePicker);
app.component("popper", Popper);
app.use(CustomVar)
app.config.globalProperties.$moment=moment;

app.mount('#app')



// if(window.navigator && navigator.serviceWorker) {
//   navigator.serviceWorker.getRegistrations()
//   .then(function(registrations) {
//     for(let registration of registrations) {
//       registration.unregister();
//     }
//   });
// }
