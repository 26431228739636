<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>
    <div class="card">
      <template v-if="preview_object">
        <div
            class="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom">
          <div>
            <h3>{{ preview_object.title }}</h3>
          </div>
          <button class="btn" @click="preview_object=null"><b class="h3"><i
              class="bi bi-x-lg"></i></b>
          </button>
        </div>
        <div class="card-body mt-0 pt-0">
          <div><b>СМИ: </b> {{massmedia.filter(x=>x.id=preview_object.mass_media)[0]?.name}}</div>
          <div><b>Статус: </b> {{ select.client_publication_status[preview_object.status] }}</div>
          <div><b>Планируемая дата: </b> {{ preview_object.desired_date }}</div>
          <div><b>Желаемая ссылка: </b> {{ preview_object.link }}</div>
          <div><b>Дата размещения: </b> {{ preview_object.publication_date||"-" }}</div>
          <div><b>Cсылка на размещённый контент: </b><a :href="preview_object.publication_url">{{preview_object.publication_url||"-"}}</a></div>
          <hr>
          <div class="mt-1" v-html="preview_object.text"></div>
        </div>
      </template>
      <template v-else-if="edit_object">
        <div
            class="card-header d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center border-bottom">
          <div>
            <h3>Запрос на размещение в СМИ</h3>
          </div>
          <button class="btn" @click="endEdit(edit_object);"><b class="h3"><i
              class="bi bi-x-lg"></i></b>
          </button>
        </div>
        <div class="card-body mt-0 pt-0">
          <div class="row">
            <div :class="'col-6 ' + (edit_object.mass_media_error?'form-group--error':'')">
              <label for="name" class="form-label mt-3">СМИ</label>
              <multi_select v-bind:model.sync="edit_object.mass_media"
                            v-bind:model_error.sync="edit_object.mass_media_error"
                            v-bind:options="massmedia"
                            v-bind:multi="false"
                            v-bind:only_value="1"></multi_select>
              <div class="error" v-if="edit_object.mass_media_error">{{ edit_object.mass_media_error }}</div>
            </div>
          </div>
          <div class="row mt-2">
            <div :class="'col ' + (edit_object.title_error?'form-group--error':'')">
              <label for="name" class="form-label mt-3">Заголовок</label>
              <input class="form-control" v-model="edit_object.title" type="text">
              <div class="error" v-if="edit_object.mass_media_error">{{ edit_object.mass_media_error }}</div>
            </div>
          </div>
          <div class="row mt-2">
            <div :class="'col ' + (edit_object.link_error?'form-group--error':'')">
              <label for="name" class="form-label mt-3">Желаемая ссылка</label>
              <input class="form-control" v-model="edit_object.link" type="text">
              <div class="error" v-if="edit_object.link_error">{{ edit_object.link_error }}</div>
            </div>
            <div :class="'col-4 ' + (edit_object.desired_date_error?'form-group--error':'')">
              <label for="name" class="form-label mt-3">Планируемая дата размещения</label>
               <date-picker v-model="edit_object.desired_date"
                     locale="ru"
                     :clearable="false"
                     format="dd.MM.yyyy"
                     model-type="yyyy-MM-dd"
                     auto-apply
                     :enable-time-picker="false"></date-picker>

              <div class="error" v-if="edit_object.desired_date_error">{{ edit_object.desired_date_error }}</div>
            </div>
          </div>
          <div :class="' mt-2 ' + (edit_object.text_error?'form-group--error':'')">
            <label for="name" class="form-label mt-3">Текст к размещению</label>
            <VueEditor
                @imageAdded="handleImageAdded"
                v-model="edit_object.text"/>
            <div class="error" v-if="edit_object.text_error">{{ edit_object.text_error }}</div>
          </div>
          <div class="mt-3" v-if="edit_object.status === 10 || !edit_object.status">
            <button href="" class="btn btn-sm btn-success ms-sm-2 "
                    v-on:click.prevent="edit_object.additional_info={};saveNote(edit_object);"><i
                class="bi bi-check-circle"></i>
              <small>Сохранить</small>
            </button>
            <button href="" class="btn btn-sm btn-danger ms-2 ms-sm-2 "
                    v-on:click.prevent="endEdit(edit_object);"><i
                class="bi bi-x-circle"></i>
              <small>Отмена</small>
            </button>
          </div>
        </div>
      </template>

      <div class="card-body links" v-else>
        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th> №</th>
              <th> СМИ</th>
              <th class="mw200">Заголовок</th>
              <th>Статус</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr>
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>
                <td>{{ massmedia.filter(x => x.id === object.mass_media)[0]?.name }}</td>
                <td>
                  {{ object.title }}<br>
                  <small class="no-wrap"><b>Планируемая дата:</b> {{ object.desired_date }}</small> <br>
                  <small class="no-wrap"><b>Желаемая ссылка:</b> {{ object.link }}</small> <br>
                </td>
                <td>
                  {{ select.client_publication_status[object.status] }} <br>
                  <template v-if="object.publication_url">
                  <small class="no-wrap"><b>Дата:</b> {{ object.publication_date }}</small> <br>
                  <small class="no-wrap"><b><a :href="object.publication_url">ссылка</a></b></small> <br>
                  </template>
                </td>

                <td>
                  <a href="" v-if="object.status === 10"
                     v-on:click.prevent="edit_object = object"
                     class="no-wrap"><i class="bi bi-pencil"></i>
                    <small>редактировать</small>
                  </a>
                  <button href="" v-if="object.status === 10" v-on:click.prevent="changeStatus(object)"
                          class="no-wrap btn btn-sm btn-success d-block mt-2">
                    <small>Отправить на публикацию</small>
                  </button>
                  <button v-else class="btn btn-sm btn-primary" @click="preview_object=object">Подробнее <i class="bi bi-three-dots"></i></button>
                </td>
              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="6">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="11">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page"></PagerList>


    </div>


  </main>
</template>

<script>
import dataMixin from './mixins.js';
import {DictionaryApi} from "@/api/dictionary";
import multi_select from "@/components/plugins/Select.vue";

export default {
  name: 'MassMedia',
  components: {multi_select},
  mixins: [dataMixin],
  data() {
    return {
      name: 'publications',
      massmedia: [],
      preview_object: null,
      edit_object: null,
      empty: {
        mass_media: null,
        title: "",
        text: "",
        desired_date: null,
        link: "",
        additional_info: {},
      },
      select: {
        client_publication_status: []
      },
      validate: {
        link: {
          func: (o) => {
            return !o.link || !this.$func.isURLValid(o.link)
          }, error: 'Неверный формат ссылки'
        },
        title: {},
        text: {},
        desired_date: {},
        mass_media: {},
      }
    }
  },
  mounted: function () {
    this.getMassMedia()
  },
  methods: {
    getMassMedia() {
      DictionaryApi.get_list('massmedia', 1, 100, '').then((resp) => this.massmedia = resp.results);
    },
    addNote: function () {
      let object = JSON.parse(JSON.stringify(this.empty));
      this.edit_object = object;
      this.objects.push(object);
    },
    changeStatus(obj) {
      let $this = this;
      DictionaryApi.send_publication($this.name, obj.id).then(() => {
        DictionaryApi.get($this.name, obj.id).then(resp =>$this.objects.splice($this.objects.indexOf(obj), 1, resp))
        if (alert) $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Запись отправлена на публикацию'
        });
      })

    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      let formData = new FormData();
      formData.append("image", file);
      DictionaryApi.upload_file_redactor(formData).then(result => {
        const url = result.data.url;
        Editor.insertEmbed(cursorLocation, "image", url);
        resetUploader();
      }).catch(err => {
        console.log(err);
      });

    }
  }
}
</script>

<style>
textarea {
  min-width: 380px;
}
</style>
