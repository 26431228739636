<template>
  <div>
    <div class="d-flex flex-row align-items-start p-2 justify-content-between">
      <div class="mt-1 row ms-2 me-2 align-items-center row" v-if="wiki && enabledDates.length">
        <label class="col ps-0">Cравнение с:</label>
        <date-picker
                v-model="date_compare"
                locale="ru"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :allowed-dates="enabledDates"
                :enable-time-picker="false">
            </date-picker>


        <label class="col ps-3 no-wrap">Отображение за:</label>
             <date-picker
                v-model="date"
                locale="ru"
                format="dd.MM.yyyy"
                model-type="yyyy-MM-dd"
                auto-apply
                :allowed-dates="enabledDates"
                :enable-time-picker="false">
            </date-picker>

      </div>


    </div>
    <div class="card d-flex flex-row" v-if="wiki">
      <div v-if="!wiki.object.id && !wiki.compare.id">Контент для ссылки ещё не получен</div>
      <div class="card-body" v-if="wiki.object.id">
        <div class="h3 badge bg-secondary">{{ $func.momentDate(wiki.object.date_create) }}</div>
        <div class="h5" v-if="!wiki.object.id">Данные не собраны или у прокта не указана ссылка на wikipedia</div>
        <div v-for="(text, index) in wiki.object.text" @click="wiki.show_diff = !wiki.show_diff">
          <p v-html="findDiff(text, wiki.compare.text[index], 'red')"></p>
        </div>

      </div>
      <div class="card-body" v-if="wiki.compare.id">
        <div class="h3 badge bg-secondary">{{ $func.momentDate(wiki.compare.date_create) }}</div>
        <div v-for="(text, index) in wiki.compare.text" @click="wiki.show_diff = !wiki.show_diff">
          <p v-html="findDiff(text, wiki.object.text[index], 'green')"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DictionaryApi} from "@/api/dictionary";

export default {
  name: "WikiChange",
  props: ["wiki_id", "active_dates"],
  components: {
  },
  data() {
    return {
      name: 'wikipedia',
      old_date: null,
      date: null,
      date_compare: null,
      wiki: null,
      enabledDates: []
    }
  },
  watch: {
    date: function (val) {
      if (this.old_date != null) this.getWiki();
      this.old_date = val
    },
    date_compare: function (val) {
      if (this.old_date != null) this.getWiki();
      this.old_date = val
    },
  },
  mounted() {
    this.enabledDates = this.active_dates
    this.getWiki()
  },

  methods: {
    getWiki: function () {
      const $this = this;
      DictionaryApi.get_changes($this.name, $this.wiki_id, this.date, this.date_compare).then(response => {
        $this.wiki = response
        if ($this.wiki.object.date_create) $this.date = $this.$moment($this.wiki.object.date_create).format('DD/MM/YYYY')
        if ($this.wiki.compare.date_create) $this.date_compare = $this.$moment($this.wiki.compare.date_create).format('DD/MM/YYYY')
      })
    },
    findDiff(text, compare, type) {
      let diff = "";
      if (compare) {
        compare = compare.split(' ')
        text.split(' ').forEach(function (val, i) {
          if (val !== compare[i]) diff += `<span class="bg-${type}">`;
          diff += val + ' ';
          if (val !== compare[i]) diff += '</span>';
        });
      }
      diff = diff.replace('=== ', '<h4>').replace(' ===', '</h4>').replace('== ', '<h2>').replace(' ==', '</h2>')
      return diff;
    },


  },
}
</script>

<style scoped>
.high {
  cursor: pointer;
  background: #ffc107;
}

.d-flex p {
  flex: 50%
}

.d-flex p:first-child {
  border-right: 1px solid #ccc;
}

.d-flex .diff {
  padding-left: 5px;
}
</style>
<style>

.bg-green {
  background-color: #72ff88;
  opacity: 0.8;
}

.bg-red {
  background-color: #ff9285;
  opacity: 0.8;
}
</style>
