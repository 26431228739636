const getOrCreateLegendList = (chart, id) => {

    const legendContainer = document.getElementById(id);
    if (legendContainer) {
        let listContainer = legendContainer.querySelector('ul');
        if (!listContainer) {
            listContainer = document.createElement('ul');
            legendContainer.appendChild(listContainer);
        }
        return listContainer;
    }
    return false;
};

const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(chart) {
        let containerID = chart.config._config.options.plugins.htmlLegend?.containerID || 'legend';
        const ul = getOrCreateLegendList(chart, containerID);
        if (ul) {
            while (ul.firstChild) {
                ul.firstChild.remove();
            }
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            items.forEach(item => {
                const li = document.createElement('li');
                li.onclick = () => {
                    const {type} = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };

                // Text
                const textContainer = document.createElement('p');
                textContainer.style.backgroundColor = `${item.fillStyle}3F`;
                textContainer.style.borderColor = item.fillStyle;
                if (item.hidden) textContainer.classList.add('hidden')

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        }
    }
};
export default htmlLegendPlugin