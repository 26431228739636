<template>
  <main class="dashboard">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div class="btn-toolbar mb-2 mb-md-0">
        <div class="btn-group me-2" v-if="$store.getters.has_cp && $store.getters.user.user_type === 'free'">
          <router-link to="/projects/add"
                       v-if="$store.getters.user.keyword_count < $store.getters.tariff.request_count && $store.getters.tariff.can_edit" type="button"
                       class="btn btn-sm blue-bg btn-primary">Создать проект <i
              class="bi bi-plus-circle ms-1"></i>
          </router-link>
          <template v-else>
            <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '0,10px' } } }">
              <span slot="reference">
                   <button class="btn btn-sm blue-bg btn-primary" disabled>Создать проект <i
                       class="bi bi-plus-circle ms-1"></i></button>
                </span>
              <template #content>
              <div class="popper text-left">
                Для данной организации достигнуто максимальное количество запросов. Всего доступно:
                {{ $store.getters.tariff.request_count }}
              </div>
              </template>

            </popper>
          </template>
        </div>
      </div>
    </div>

    <div class="card" v-for="project in project_snapshots">
      <div class="card-header" :style="{'background-color': 'rgb(255 255 255 / 3%)'}">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="col-sm-3">
            <h4>{{ project.info.name }}</h4>
          </li>
          <li>
            <CountryChoice v-if="project && project.info.countries"
                           v-bind:active_country.sync="active_contries_for_projects[project.info.id]"
                           v-bind:project_id="project.info.id"
                           v-bind:countries="project.project_tr_countries"
                           v-bind:changeCountry="changeCountry">
            </CountryChoice>
          </li>
          <li class="d-flex flex-column mb-3 col">


            <router-link  class="d-flex flex-row justify-content-end text-dark" v-if="project.info.negative_info"
                 :to="{ name: 'project_forecast', params: { id: project.info.id }}">

              <div class="p-2"> План: <b>{{ project.info.negative_info.clf || 0 }},</b></div>
              <div class="p-2"> Факт ТОП {{ project.info.active_state }}:
                <b :class="`${((project.info.negative_info.negative_fact||0)  > (project.info.negative_info.clf||0 ))?'text-danger':((project.info.negative_info.negative_fact||0)  < (project.info.negative_info.clf||0 ))?'text-success':'text-dark'}`">
                  {{ project.info.negative_info.negative_fact || 0 }}</b>
              </div>
            </router-link>

          </li>
        </ul>
      </div>

      <div class="row">
        <sermDetail v-if="project.info.country_data.find(x => x.code === active_contries_for_projects[project.info.id].code)"
            v-bind:project="project.info.country_data.find(x => x.code === active_contries_for_projects[project.info.id].code).data.project_stats.stats"
            v-bind:date="project.presentation_date"
            v-bind:state="10"
            v-bind:full_info_show="false"
            v-bind:active_country="active_contries_for_projects[project.info.id]">
        </sermDetail>

        <div v-else >Такой страны нет в проекте</div>

      </div>
      <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        <router-link :to="{ name: 'project_snap_detail', params: { id: project.info.id }}"
                     class="btn btn-outline-primary btn-sm m-2">Смотреть полностью<i
            class="bi bi-arrow-right ms-2"></i></router-link>
      </div>
    </div>
    <template v-for="project in projects">
        <FrontProject :project="project"></FrontProject>
      </template>

  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import InfoBlock from '@/components/includes/InfoBlock.vue';
import CountryChoice from '@/components/CountryChoice';
import sermDetail from "@/views/project/Serm.vue";
import FrontProject from "@/views/FrontProject.vue";


export default {
  name: 'FrontPage',
  components: {
    InfoBlock,
    CountryChoice, sermDetail, FrontProject
  },

  data() {
    return {
      projects: [],
      project_snapshots: [],
      active_contries_for_projects: {}
    }
  },
  computed: {
    search: {
      get: function () {
        return this.$store.getters.search;
      },
      set: function () {
      }
    }
  },
  mounted: function () {
    ProjectApi.list().then(projects => this.projects = projects.results);
    ProjectApi.get_project_list().then(response => {
      this.project_snapshots = response
      let countries = {}
      for (let project of this.project_snapshots) {
        countries[project.info.id] = project.info.active_country_code
      }
      this.active_contries_for_projects = countries
    });
  },

  methods: {
    changeCountry: function (country, project_id) {
      this.active_contries_for_projects[project_id] = country
    },

  }
}

</script>

<style scoped>
.card {
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 0;
}

</style>
