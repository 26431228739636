<script>
import {Line} from "vue-chartjs";
import Chart from 'chart.js/auto';
import ChartDataLabels from "chartjs-plugin-datalabels";

const getOrCreateLegendList = (chart, id) => {

  const legendContainer = document.getElementById(id);
  if (legendContainer) {
    let listContainer = legendContainer.querySelector('ul');
    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.style.display = 'flex';
      listContainer.style.flexDirection = 'row';
      listContainer.style.margin = '0';
      listContainer.style.padding = '0';
      legendContainer.appendChild(listContainer);
    }
    return listContainer;
  }
  return false;
};

const htmlLegendPlugin = {
  id: 'htmlLegend',
  afterUpdate(chart, args, options) {
    let containerID = options.containerID || 'legend';
    const ul = getOrCreateLegendList(chart, containerID);
    if (ul) {
      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(item => {
        const li = document.createElement('li');
        li.style.alignItems = 'center';
        li.style.cursor = 'pointer';
        li.style.display = 'flex';
        li.style.flexDirection = 'row';
        li.style.marginLeft = '10px';

        li.onclick = () => {
          const {type} = chart.config;
          if (type === 'pie' || type === 'doughnut') {
            // Pie and doughnut charts only have a single dataset and visibility is per item
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
          }
          chart.update();
        };

        // Color box
        const boxSpan = document.createElement('span');
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        // boxSpan.style.borderWidth = item.lineWidth + 'px';
        boxSpan.style.borderWidth = '2px';
        boxSpan.style.display = 'inline-block';
        boxSpan.style.height = '1px';
        boxSpan.style.marginRight = '10px';
        boxSpan.style.width = '40px';

        // Text
        const textContainer = document.createElement('p');
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = '0';
        textContainer.style.padding = '0';
        textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }
  }
};


Chart.register(ChartDataLabels);
Chart.register(htmlLegendPlugin);
Chart.defaults.plugins.datalabels.align = "top";
Chart.defaults.plugins.datalabels.font = {
  weight: 'bold',
  size: 16,
}

Chart.defaults.plugins.legend.display = false;

export default {
  name: "BarChart",
  extends: Line,
  props: {
    data: {type: Object, default: null},
    options: {type: Object, default: null},
    show_legend: {type: Boolean, default: true}
  },
  data: () => ({}),
  computed: {
    chartData: function () {
      return this.data;
    },
    chartOptions: function () {
      return {...this.defaultOptions, ...this.options}
    },
    defaultOptions() {

      return {
        responsive: true,
        layout: {
          padding: 20,
        },
        maintainAspectRatio: false

      }
    }
  },
};
</script>
