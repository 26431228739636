<template>
  <main class="dashboard">
    <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{ current_page.name }}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body links">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Референс</th>
              <th scope="col">Кол-во текстов</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col">Статус</th>
              <th scope="col"></th>
              <th scope="col" v-if="$store.getters.user.is_staff">ID</th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr class="edit" v-if="object.edit">
                <td colspan="5">
                  <div class="form p-3 border rounded  border-2 border-primary-subtle">

                    <div class="form-group">
                      <label>Язык</label>
                      <select class="form-select" v-model="object.lang"
                              @change="changeValue(object)">
                        <option v-for="lang in LANG" :value="lang" :key="lang">{{ lang }}</option>
                      </select>
                    </div>
                    <div :class="object.n_questions_error?'form-group--error':''">
                      <label for="">Желаемое количество текстов <i>(от 1 до 100)</i></label>
                      <input class="form-control" type="number" v-model="object.n_questions" max="100" min="1">
                    </div>
                    <div class="error" v-if="object.n_questions_error">{{ object.n_questions_error }}</div>
                    <div :class="object.max_length_error?'form-group--error':''">
                      <label for="">Максимальное количество символов в одном тексте <i>(от 100 до 7000)</i></label>
                      <input class="form-control" @change="changeValue(object)" type="number" v-model="object.max_length"
                             max="7000" min="100">
                    </div>
                    <div class="error" v-if="object.max_length_error">{{ object.max_length_error }}</div>
                    <hr>
                    <div :class="object.article_prompt_prefix_error?'form-group--error':''">
                      <label>Префикс промпта</label>
                      <textarea class="form-control" name="" cols="5" rows="5" v-model="object.article_prompt_prefix">
                      </textarea>

                    </div>
                    <div :class="object.text_error?'form-group--error':''">

                      <label>Тема материалов</label>
                      <textarea class="form-control" name="" cols="10" rows="5"
                                v-on:keyup="object.text_error = false"
                                v-model="object.text"></textarea>

                    </div>
                    <div class="error" v-if="object.text_error">{{ object.text_error }}</div>
                    <div :class="object.article_prompt_postfix_error?'form-group--error':''">

                      <label>Постфикс промпта</label>
                      <textarea class="form-control" name="" cols="5" rows="5"
                                v-model="object.article_prompt_postfix"></textarea>

                    </div>
                    <hr>
                    <div :class="object.headline_prompt_prefix_error?'form-group--error':''">

                      <label>Префикс промпта заголовка</label>
                      <textarea class="form-control" name="" cols="5" rows="5"
                                v-model="object.headline_prompt_prefix"></textarea>

                    </div>
                    <hr>
                    <div class="row">
                      <div class="input-group mt-1" v-for="(person_reference, index) in object.person_references">

                        <label for="" class="input-group-text">Вставка отсылки #{{ index + 1 }} в абзац номер</label>
                        <input class="form-control" v-model="person_reference.ref_position" type="number">
                        <label for="" class="input-group-text">Отсылка к персоне #{{ index + 1 }}</label>
                        <input class="form-control" v-model="person_reference.ref_name" type="text">
                        <div class="buttons" style="width: 70px">
                          <button class="btn btn-success"
                                  @click="object.person_references.push({ref_position: index+2, ref_name: ''})">+
                          </button>
                          <button class="btn btn-danger" v-if="index"
                                  @click="object.person_references.splice(index, 1)">-
                          </button>
                        </div>
                      </div>

                    </div>
                    <hr>
                    <div class="mt-2">
                      <label for="">Отправить на Email</label>
                      <input class="form-control" type="email" v-model="object.email">
                    </div>
                    <div class="d-flex mt-3">
                      <button class="btn btn-success" v-on:click="saveNote(object)"><i
                          class="bi bi-check-circle me-2"></i>
                        <small>Сохранить</small>
                      </button>
                      <br>
                      <button class="btn btn-danger ms-2" v-on:click.prevent="endEdit(object)"><i
                          class="bi bi-x-circle me-2"></i>
                        <small>Отмена</small>
                      </button>

                    </div>
                  </div>
                </td>

              </tr>
              <tr v-else>
                <td>{{ (index + 1) + ((page - 1) * size) }}</td>

                <td class="text-area">
                  <template v-if="object.generated_content">
                    <a :href="object.generated_content" target="_blank">{{ object.text }} <i>( символов
                      {{ object.max_length }})</i>
                      <i class="bi bi-file-arrow-down ms-1"></i>
                    </a>

                  </template>
                  <template v-else>{{ object.text }} <i v-if="['agreement', 'agreed'].indexOf(object.status) < 0">( символов {{ object.max_length }})</i></template>
                </td>
                <td>{{ object.ready }}<span v-if="['agreement', 'agreed'].indexOf(object.status) < 0">/{{ object.n_questions }}</span></td>
                <td>{{ object.date }}</td>
                <td>{{ (object.status==="end")?"Завершён":object.get_status_display }}</td>
                <td>
                   <router-link v-if="!object.edit && (['agreement', 'agreed'].indexOf(object.status) + 1)"
                            class="no-wrap btn btn-primary btn-sm" :to="{name: 'content_detail', params: {id: object.id}}">
                      <small>Подроднее</small><i class="bi bi-chevron-right"></i>
                    </router-link>
                  <div class="d-flex" v-if="!object.status || (['new', 'error'].indexOf(object.status) + 1)">

                    <button v-if="!object.edit"
                            v-on:click.prevent="object.edit = true;"
                            class="no-wrap btn btn-primary btn-sm"><i class="bi bi-pencil"></i>
                      <small>редактировать</small>
                    </button>

                    <button v-if="object.id" @click="sendToGenerate(object.id)"
                            class="btn btn-sm btn-success no-wrap ms-1">Создать
                      <i class="bi bi-question-circle"
                         title="После запуска данные станут недоступны для редактирования"></i>
                    </button>
                  </div>
                  <div v-if="object.generated_content && !object.generated_content.endsWith('.docx')">
                    <button class="btn btn-sm " title="Перепаковать архив" @click="recreateArchive(object)"><i class="bi bi-arrow-clockwise"></i></button>
                  </div>
                </td>
                <td v-if="$store.getters.user.is_staff">{{object.id}}</td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="7">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="7">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>


  </main>
</template>

<script>
import {DictionaryApi} from '@/api/dictionary'
import dataMixin from '@/views/dictionary/mixins.js';

export default {
  name: 'ContentService',
  mixins: [dataMixin],
  data() {
    return {
      name: 'contents',

      empty: {
        content_type: 'text',
        text: '', date: '', edit: true,
        lang: 'ru',
        n_questions: 1,
        max_length: 2000,
        article_prompt_prefix: '',
        article_prompt_postfix: '',
        headline_prompt_prefix: '',
        email: '',
        person_references: [{ref_position: 1, ref_name: ''}],
      },
      validate: {
        'content_type': {},
        'text': {
          func: (o) => {
            return o.content_type === 'text' && !o.text.length
          }
        },
        'n_questions': {
          error: 'Неправильное значение, допустимое значение в промежутке от 1 до 100',
          func: (o) => !o.n_questions || o.n_questions > 100
        },
        'max_length': {
          error: 'Неправильное значение, допустимое значение в промежутке от 100 до 7000',
          func: (o) => !o.max_length || o.max_length > 7000 || o.max_length < 100
        },
      },
      LANG: ['ru', 'en'],
      ARTICLE_GENERATION_PROMPT_PREFIX_DICT: {
        "ru": "Напиши статью размером не менее {0} символов на эту тему:\n",
        "en": "Write an article with at least {0} symbols based on this topic:\n",
      },
      HEADLINE_GENERATION_PROMPT_DICT: {
        "ru": "Напиши заголовок для этого текста:\n",
        "en": "Write a headline for this text:\n",
      },
    }
  },
  mounted() {
    if (!String.format) {
      String.format = function (format) {
        let args = Array.prototype.slice.call(arguments, 1);
        return format.replace(/{(\d+)}/g, function (match, number) {
          return typeof args[number] != 'undefined'
              ? args[number]
              : match
              ;
        });
      };
    }
  },
  methods: {
    changeValue(object) {
      let index = this.objects.indexOf(object);
      object.article_prompt_prefix = String.format(this.ARTICLE_GENERATION_PROMPT_PREFIX_DICT[object.lang], object.max_length);
      object.headline_prompt_prefix = this.HEADLINE_GENERATION_PROMPT_DICT[object.lang];
      this.objects.splice(index, 1, object);
    },
    addNote: function () {
      let new_empty = JSON.parse(JSON.stringify(this.empty));
      new_empty.article_prompt_prefix =  String.format(this.ARTICLE_GENERATION_PROMPT_PREFIX_DICT[new_empty.lang], new_empty.max_length);
      new_empty.headline_prompt_prefix = this.HEADLINE_GENERATION_PROMPT_DICT[new_empty.lang];

      this.objects.push(new_empty);
    },
    sendToGenerate(id) {
      let $this = this;
      DictionaryApi.generate_text(id).then(response => {
        $this.objects.splice($this.objects.findIndex(x => x.id === id), 1, response);
        $this.checkStatusText(id)
      })
    },
    recreateArchive(object) {
      let $this = this;
      DictionaryApi.recreate_archive(object.id).then(response => {
        $this.objects.splice($this.objects.findIndex(x => x.id === object.id), 1, response);
      })
    },
    checkStatusText(id) {
      let $this = this;
      DictionaryApi.get(this.name, id).then(response => {
        $this.objects.splice($this.objects.findIndex(x => x.id === id), 1, response);
        if (!response.generated_content && response.status !== 'error') setTimeout(function () {
          $this.checkStatusText(id)
        }, 5000)
      })
    }
  },

}

</script>


<style>
textarea {
  min-width: 380px;
}
</style>
