<template>
<div>

    <a :style="`position: relative;display:block; width: 500px; height: 200px`" href="" class="text-decoration-none"
       @click.prevent="$modal('show', `chart_${link_id}`)">
      <Line v-if="chart_data"
                v-bind:data="small_chart_data"
                v-bind:options="baseChartOptions"
                v-bind:show_legend="false"/>

    </a>
  <modal :modal_id="`chart_${link_id}`">
    <template v-slot:body>

            <div id="legend" style="max-width: 300px;" class="line-legend "></div>
            <div class="card-body pb-5" style="height: 100%">

              <Line v-if="chart_data" class="mt-4"
                        v-bind:data="chart_data"
                        v-bind:options="bigChartOptions"
                        v-bind:show_legend="true"/>
          </div>
    </template>
    </modal>
</div>

</template>

<script>
import {LinkApi} from '@/api/links'
import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, Filler, PointElement)
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(ChartDataLabels);

ChartJS.defaults.plugins.legend.display = false;
ChartJS.defaults.set('plugins.datalabels', {
  color: '#000000',
  font:{
    weight: "bold",
  },
  offset: 0,
  align: "top"
});


export default {
  name: 'ResponseChart',
  components: {
    Line
  },
  props: ["link_id"],
  data() {
    return {
      datacollection: null,
      id: null
    }
  },

  watch: {
    link_id(){
      this.getData()
    }
  },

  mounted: function () {
    this.getData();
    this.id = this._uid
  },
  computed: {
    baseChartOptions() {
      let max_val = Math.max(...this.datacollection.datasets.map(x => x.data).flat().filter(x => x <= 100));
      max_val = max_val > 100 ? 100 : max_val
      return {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 1,
        animation: {
          duration: 0
        },
        plugins: {
          datalabels: false
        },
        layout: {
          padding: 20
        },
        scales: {
          x:
              {
                grid: {
                  display: false
                },
                ticks: {
                  display: true,
                  stepSize: 50,
                },
              },
          y:
              {
                grid: {
                  display: false
                },

                ticks: {
                  display: true,
                  stepSize: 100,
                },
                reverse: true,
                maxTicksLimit: max_val,
                suggestedMax: max_val,
                max: max_val,
                min: 0

              }
        },
      }
    },
    bigChartOptions() {
      let opt = JSON.parse(JSON.stringify(this.baseChartOptions));
      opt.scales.x = {...opt.scales.x, ...{ticks: {display: true}}};
      opt.scales.y = {...opt.scales.y, ...{ticks: {display: true}}};
      return opt
    },
    chart_data() {
      return this.datacollection;
    },
    small_chart_data() {
      let chart_data = JSON.parse(JSON.stringify(this.chart_data));
      if (chart_data && chart_data.labels) {
        let new_data = [], count = chart_data.labels.length+1;
        for (let i in chart_data.datasets) {
          let d = chart_data.datasets[i];
          let n_d = {
            borderColor: d.borderColor,
            data: count > 30 ? d.data.slice(count - 30, count) : d.data,
            fill: d.fill,
            label: d.label,
            lineTension: d.lineTension,
            type: d.type,
          };
          new_data.push(n_d);
        }
        chart_data = {
          labels: count > 30 ? chart_data.labels.slice(count - 30, count) : chart_data.labels,
          datasets: new_data,
        };
      }
      chart_data.labels = chart_data.labels.map((l, i) => {
        return (!i || (i + 1) === chart_data.labels.length) ? l : ''
      })
      return chart_data;
    },
  },

  methods: {
    formatten(value) {
      if (this.datacollection) {
        let val = this.datacollection.labels[value];
        if (!val) val = this.datacollection.labels[value - 1]
        return `${val}`;
      }
      return '';
    },
    getData: function () {
      this.fillData()
    },
    fillData() {
      let $this = this;
      LinkApi.get_chart_data(this.link_id, 100, "").then(response => {
        let labels = response.labels;
        // if (labels.length && labels.length < 30) {
        //   Date.prototype.addDays = function (days) {
        //     let date = new Date(this.valueOf());
        //     date.setDate(date.getDate() + days);
        //     return date;
        //   };
        //   let now = new Date(), last = labels[labels.length - 1];
        //
        //   while (labels.length < 10) {
        //
        //     let last_date = new Date(now.getFullYear(), parseInt(last.split('.')[1]), parseInt(last.split('.')[0]));
        //     let new_date = last_date.addDays(1);
        //     last = `${String(new_date.getDate()).padStart(2, '0')}.${String(new_date.getMonth()).padStart(2, '0')}`;
        //     labels.push(last)
        //   }
        // }
        $this.datacollection = {
          labels: labels,
          datasets: response.data
        };

      })
    },

  }
}

</script>
<style>
.line-legend li:first-child {
  position: relative !important;
}
</style>

