<template>
  <nav v-if="pager.length>1">
    <ul class="pagination justify-content-center" style="flex-wrap: wrap">
      <li :class="`page-item${p===page?' active':''}`" v-for="p in pager">
        <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="updatePage(p)" href="">{{ p }}</a>
        <span class="page-link" v-else>{{ p }}</span>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "PagerList",
  props: ["pager", "page", "updatePage"],
  data: () => ({}),

  methods: {}
}
</script>

<style scoped>

</style>
