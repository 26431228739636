import { HTTP } from "@/api/common";

export const ChatApi = {
  list(id, page) {
    return HTTP.get(`chat/${id}/get_messages/?size=15&page=${page}`).then((response) => {
      return response.data;
    });
  },
 
  get(obj_id, content_type) {
    return HTTP.post(`chat/get_chat/`, {obj_id: obj_id, content_type: content_type}).then((response) => {
      return response.data;
    });
  },
  add_message(id, message) {
    return HTTP.post(`chat/${id}/add_message/`, message,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
      return response.data;
    });
  },

};
