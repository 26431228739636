<template>
  <main class="dashboard">
        <div class="d-flex pt-3 pb-2 mb-3 border-bottom">
      <h1 class="h2">{{current_page.name}}</h1>
    </div>
    <div class="text" v-html="current_page.description"></div>

    <div class="card">
      <div class="card-body links">

        <div class="table-responsive">
          <table class="table dict">
            <thead>
            <tr>
              <th scope="col"> №</th>
              <th scope="col" class="mw200">Соц сеть</th>
              <th scope="col" class="mw200">Регион</th>
              <th scope="col" class="mw200">Модель определения психотипов</th>
              <th scope="col" class="mw200">Комментарий</th>
              <th scope="col">Дата добавления / изменения</th>
              <th scope="col"></th>
            </tr>
            </thead>
            <tbody>
            <tr class="empty"></tr>
            <template v-for="(object, index) in objects">
              <tr :class="object.edit?'edit':''">
                <td>{{(index + 1) + ((page - 1) * size)}}</td>
                <template v-if="object.edit">
                  <td>
                    <div :class="object.social_error?'form-group--error':''">
                      <label class="d-none">Соц сеть</label>
                      <select v-model="object.social" class="form-select">
                        <option v-for="social in dicts.social" :value="social.id">{{social.name}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.social_error">{{object.social_error}}</div>
                  </td>
                  <td>
                    <div :class="object.region_error?'form-group--error':''">
                      <label class="d-none">Регион</label>
                      <select v-model="object.region" class="form-select">
                        <option v-for="region in dicts.region" :value="region.id">{{region.name}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.region_error">{{object.region_error}}</div>
                  </td>
                  <td>
                    <div :class="object.psychotype_error?'form-group--error':''">
                      <label class="d-none">Модель определения психотипов</label>
                      <select v-model="object.psychotype" class="form-select">
                        <option v-for="psychotype in dicts.psychotype" :value="psychotype.id">{{psychotype.name}}</option>
                      </select>
                    </div>
                    <div class="error" v-if="object.psychotype_error">{{object.psychotype_error}}</div>
                  </td>
                  <td>
                      <div :class="object.text_error?'form-group--error':''">
                        <label class="d-none">Комментарий</label>
                      <textarea class="form-control" name="" cols="50" rows="10"
                                v-on:keyup="object.text_error = false"
                                v-model="object.text"></textarea>
                      </div>
                      <div class="error" v-if="object.text_error">{{object.text_error}}</div>
                  </td>

                </template>
                <template v-else>
                  <td>{{object.social_name}}</td>
                  <td>{{object.region_name}}</td>
                  <td>{{object.psychotype_name}}</td>
                  <td class="text-area">{{object.text}}</td>
                </template>

                <td>{{object.date}}</td>
                <td>
                  <a href="" v-if="!object.edit" v-on:click.prevent="object.edit = true;"
                     class="no-wrap"><i class="bi bi-pencil"></i>
                    <small>редактировать</small>
                  </a>
                  <template v-else>
                    <a href="" class="text-success ms-sm-2 no-wrap" v-on:click.prevent="saveNote(object)"><i
                      class="bi bi-check-circle"></i>
                      <small>Определить психотип</small>
                    </a> <br>
                    <a href="" class="text-danger ms-sm-2 no-wrap" v-on:click.prevent="endEdit(object)"><i
                      class="bi bi-x-circle"></i>
                      <small>Отмена</small>
                    </a>
                  </template>
                </td>

              </tr>
            </template>
            <tr v-if="!objects.length">
              <td colspan="7">
                Не добавлено ни одной записи
              </td>
            </tr>
            <tr v-if="objects.length < max_length">
              <td colspan="7">
                <button class="btn btn-success" v-on:click="addNote" title="Добавить запись">+</button>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <PagerList v-bind:pager="pager" v-bind:updatePage="updatePage" v-bind:page="page" ></PagerList>


    </div>


  </main>
</template>

<script>
  import dataMixin from './mixins.js';
  export default {
    name: 'PsychotypesService',
    mixins: [dataMixin],
    data() {
      return {
        name: 'psychotype',
        empty: {social: '', region: '', date: '', edit: true, psychotype: '', text: ''},
        validate: {
          'social': {}, 'region': {}, 'psychotype': {},
        },
        dicts: {social: [], region: [], psychotype: []}
      }
    }
  }

</script>


<style>
  textarea {
    min-width: 380px;
  }
</style>
