<template>

  <main class="form-signin">
    <div class="form-registration">

      <div class="row">
        <form @submit.prevent="registration" class="col-sm">
          <div class="breadcrumb text-white mb-4">
            <a :href="$store.getters.current_domain">Главная</a>/ <span>Регистрация</span>
          </div>
          <h3 class="h3 mb-3 text-white text-center">Регистрация</h3>
          <div class="card" style="height: 18rem" v-if="reg_end">

            <div class="card-body">
              <h4 class="card-text" style="text-align: center">
                Вы успешно зарегистрировались на сайте. <br>
                Пожалуйста подтвердите свою регистрацию перейдя по
                ссылке, которая Вам была отправлена в письме на указанный Вами E-Mail.
              </h4>
            </div>

          </div>
          <template v-if="!reg_end">
            <div :class="'form-floating '">
              <input class="form-control" required v-model="name" placeholder="ФИО">
              <label>ФИО</label>
              <span class="bi-place">
            <popper trigger="click">
              <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
              <template #content>
                <div class="popper text-left">
                  Введите Фамилию и Имя используя заглавные и строчные буквы  русского алфавита
                </div>
              </template>

            </popper>
          </span>
            </div>
            <div :class="'form-floating mt-2 '">
              <input class="form-control" required v-model="username" type="email" placeholder="Email">
              <label>Email</label>
              <span class="bi-place">
            <popper trigger="click">
              <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
              <template #content>
                <div class="popper text-left">
                 Введите Ваш email используя заглавные и строчные буквы латинского алфавита и служебные символы @._-
                </div>

              </template>
            </popper>
          </span>
            </div>


            <div
                :class="'form-floating mt-2 '">
              <input class="form-control" required type="text" v-model="organization"
                     placeholder="Организация">
              <label>Организация</label>
              <span class="bi-place">
            <popper trigger="click">
              <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
              <template #content>
                <div class="popper text-left">
                Введите юридическое наименование компании из договора
                </div>
              </template>

            </popper>
          </span>
            </div>

            <div :class="'form-floating mt-2 '">
              <input class="form-control" required :type="show_pass?'text':'password'" v-model="password"
                     placeholder="Пароль">
              <label>Пароль</label>
              <i v-on:click="show_pass=!show_pass" style="right: 40px"
                 :class="`bi bi-eye${show_pass?' active':''}`"></i>
              <span class="bi-place">
            <popper trigger="click">
               <span slot="reference">
                   <i :class="`bi bi-info-circle`"></i>
                </span>
              <template #content>
              <div class="popper text-left">
                 Введите пароль используя буквы латинского алфавита A-Z, a-z, цифры 0-9 и служебные символы !"№;
                </div>
              </template>

            </popper>
          </span>
            </div>


            <div class="form-floating mt-2 password">
              <input class="form-control" :type="show_pass?'text':'password'" required v-model="repeat_password"
                     placeholder="Повторите пароль">
              <i v-on:click="show_pass=!show_pass" :class="`text-dark bi bi-eye${show_pass?' active':''}`"></i>
              <label>Повторите пароль</label>
            </div>
            <div class="checkbox mb-3 mt-2">
              <input id="id_remember" class="black" type="checkbox" required v-model="agree" value="remember-me">
              <label class="text-white" for="id_remember">
                Согласен с <a target="_blank" :href="agree_link">условиями обработки персональных данных</a>
              </label>
            </div>


            <button class="w-100 btn btn-lg btn-primary mt-3" type="submit">Зарегистрироваться</button>
          </template>
          <div class="mt-3 d-flex justify-content-around">
            <router-link to="/forgot_password/">Забыли пароль?</router-link>
            |
            <router-link to="/login/">Авторизация</router-link>
          </div>
        </form>

      </div>
    </div>
  </main>

</template>
<script>
import {UserApi} from '@/api/user'

const mustBeEmail = (value) => /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/.test(value);
const mustBeCyrillic = (value) => /^[\u0400-\u04FF, ]+$/.test(value);
const truePassword = (value) => /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W\w])[A-Za-z\d\W\w]{8,}$/.test(value);
export default {

  name: 'UserRegistration',
  data() {
    return {
      name: "",
      password: "",
      repeat_password: "",
      username: "",
      organization: "",
      agree: "",
      show_pass: false,
      reg_end: false,
    }
  },
  computed: {
    agree_link() {
      return location.origin.includes("orion") ? "/user-agreement" : "https://ton-reputation.com/user-agreement/"
    }
  },
  components: {},
  // validations: {
  //   organization: {
  //     required,
  //   },
  //   agree: {
  //     required,
  //   },
  //   username: {
  //     required,
  //     mustBeEmail,
  //   },
  //   name: {
  //     required,
  //     mustBeCyrillic
  //   },
  //   password: {
  //     required,
  //     truePassword,
  //     minLength: minLength(8)
  //   },
  //   repeat_password: {
  //     required,
  //     sameAsPassword: sameAs('password')
  //   },
  // },
  mounted: function () {

  },
  methods: {
    registration: function (e) {
      e.preventDefault();
      let $this = this,
          user = {username: this.username, name: this.name, password: this.password, organization: this.organization};
      UserApi.registration(user).then(response => {
        if (response.data['errors']) {
          for (let i in response.data['errors']) {
            $this.$notify({
              group: 'alert',
              type: 'error ',
              text: response.data['errors'][i]
            })
          }
        } else $this.reg_end = true;

      });

    }
  }
}
</script>
<style scoped lang="scss">
.bi-place {
  cursor: pointer;

  i {
    top: 50%;
    transform: translateY(-50%);
    right: -25px;
    position: absolute;
    color: #fff;
  }
}
</style>
