<template>


  <nav :class="`pb-4 text-white sidebar ${!$store.getters.sidebar_state?'':'collapse'}`">
    <div class="hide-sidebar" style="z-index: 2" @click="$store.dispatch('toggle_sidebar')">
      <i :class="`bi bi-chevron-double-${$store.getters.sidebar_state?'right':'left'}`"></i>

    </div>
    <div class="menu-group mt-4">
      <template v-for="page in $store.getters.pages">
        <template v-if="page.app === 'profile'">

          <router-link active-class="active" class="btn btn-menu" :title="page.name" to="/" exact>
            <i :class="'bi bi-'+ico_page[page.slug]"></i>
            <span>Цифровой профиль</span>
            <span><i class="bi bi-chevron-down" @click="toggleMenu('project_menu', $event)"></i></span>
          </router-link>

          <div id="project_menu" class="filter">
            <ul>
              <li v-for="project in projects">
                <router-link active-class="active" class="text-white" :to="`/projects/${project.id}/`">
                  {{ project.name }}
                </router-link>

              </li>
            </ul>
          </div>

        </template>
        <template v-else>
          <router-link active-class="active" class="btn btn-menu" :title="page.name" :to="`/${page.slug}/`">
            <i :class="'bi bi-'+ico_page[page.slug]"></i>
           <span> {{ page.name }}</span>
          </router-link>
        </template>
      </template>

    </div>


  </nav>

</template>

<script>
import {ProjectApi} from "@/api/project";

export default {
  name: 'left_menu',
  data() {
    return {
      projects: [],
      hide: false,
            ico_page:{
         "reviews": "chat-right-heart",
         "massmedia": "newspaper",
         "wikipedia": "wikipedia",
         "socials": "messenger",
         "cp": "person-circle",
         "contents": "body-text",
         "targets": "bullseye",
         "psychotype": "people",
         "comments": "wechat",
         "agents": "person-gear",
         "monitoring": "globe-americas",
         "seo": "graph-up-arrow",
         "tonality": "signpost-split",
         "extraction": "journal-x",
      }
    }
  },
  computed: {
  },
  mounted: function () {
    document.querySelectorAll(".menu .link-dark.rounded").forEach(item => {
      item.addEventListener('click', () => {
        document.getElementById('sidebarMenu').classList.remove("show");
      })
    });
    ProjectApi.list(true).then(projects => this.projects = projects.results);
  },
  methods: {
    toggleMenu(_id, e) {
      e.preventDefault()
      e.target.classList.toggle('bi-chevron-down')
      e.target.classList.toggle('bi-chevron-up')
      document.getElementById(_id).classList.toggle('open')
    }
  }
}
</script>
<style>
.child-menu {
  border-left: 1px solid #363535;
  padding-left: 5px !important;
  margin-left: 5px;
  padding-top: 10px;
}
</style>