<template>
  <main class="dashboard">
    <div
        class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <h2>{{ name }}</h2>
      <BackButton></BackButton>
    </div>


    <div class="card">
      <div class="card-header">
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="input-group mb-3">
            <div class="form-group">
              <label>Тональность</label>
              <MultiSelect v-if="filter_tones.length"
                           v-bind:model.sync="filter.tone"
                           v-bind:options="filter_tones"
                           v-bind:multi="true"
                           v-bind:only_value="true"
                           v-bind:name="''"></MultiSelect>
            </div>
            <div class="form-group ms-3">
              <label>Запрос</label>
              <select class="form-select" v-model="filter.keyword">
                <option value=""></option>
                <template v-for="keyword in keywords">
                  <option :value="keyword.id">{{ keyword.name }}</option>
                </template>
              </select>
            </div>

            <!--            <div class="ms-3">-->
            <!--              <label for="uniq_link">Уникальные</label>-->
            <!--              <br>-->

            <!--              <div class="form-check form-switch ms-1 pt-2">-->
            <!--                <input class="form-check-input" v-model="filter.uniq" type="checkbox" role="switch" id="uniq_link">-->
            <!--                <label class="form-check-label" for="uniq_link">Уникальные</label>-->
            <!--              </div>-->

            <!--            </div>-->

            <div class="form-group ms-3">
              <label>ТОП</label>
              <select class="form-select" v-model="filter.state">
                <option value=""></option>
                <option v-for="state in states" :value="state">{{ state }}</option>
              </select>
            </div>

          </div>
        </div>
        <div class="row">


          <div class="form-group ps-sm-2 col-xl-4">

            <label>Поиск по ссылке</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" v-model="q" aria-describedby="button-query">


            </div>
          </div>
          <div class="form-group ps-sm-2 col-xl-2">
            <label for="" class="text-white">Поиск</label>
            <button class="btn btn-primary form-control" v-on:click.prevent="getData()" type="button"
                    id="button-query"><i class="bi bi-search"></i>Показать
            </button>
          </div>

        </div>
        <div class="col">
          <CountryChoice v-if="active_country.code" v-bind:active_country.sync="active_country"
                         v-bind:project_id="$route.params.id"
                         v-bind:countries="project.countries"
                         v-bind:changeCountry="changeCountry"></CountryChoice>
        </div>
      </div>
      <div class="card-body links">

        <div class="table-responsive" style="height: calc(100vh - 370px)">
          <table class="table">
            <thead>
            <tr>
              <th scope="col">№ п/п</th>
              <th scope="col">Ссылка<i v-on:click="changeOrder('link')" :class="`link ${getOrderClass('link')}`"></i>
              </th>
              <th scope="col">Домен<i v-on:click="changeOrder('domain')" :class="`link ${getOrderClass('domain')}`"></i>
              </th>
              <th scope="col" style="min-width: 300px">Запрос/Позиции</th>
              <th scope="col">Тон<i v-on:click="changeOrder('tone_name');"
                                    :class="`link ${getOrderClass('tone_name')}`"></i></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(link, index) in table">
              <tr>
                <td>{{ index + 1 + (page - 1) * size }}</td>
                <td>
                  <a :href="link.link" target="_blank" :style="$func.getClass(link, true)">{{ link.link }}</a>
                </td>
                <td>{{ link.domain }} <br>
                  <a v-if="link.has_contents" href=""
                     v-on:click.prevent="getContent(link.link_obj_id ? link.link_obj_id : link.id)">Контент
                  </a>
                </td>
                <td>
                  <div class="keys row pe-2">
                    <div class="key col-10"></div>
                    <div class="col-1 text-center"><i class="yandex"></i></div>
                    <div class="col-1 text-center"><i class="google"></i></div>
                  </div>
                  <div class="keys row pe-2" v-for="keyword in link.keyword">
                    <div class="key col-10">{{ keyword.name }}:</div>
                    <div class="col-1 text-center">
                      <div class="" v-for="(place, index) in keyword.place.filter(x=>x.engine==='yandex')"> {{
                          place.place
                        }}
                        <template v-if="keyword.place.filter(x=>x.engine==='yandex')[index+1]">,</template>
                      </div>
                    </div>
                    <div class="col-1 text-center">
                      <div class="place" v-for="(place, index) in keyword.place.filter(x=>x.engine==='google')">
                        {{ place.place }}
                        <template v-if="keyword.place.filter(x=>x.engine==='google')[index+1]">,</template>
                      </div>
                    </div>

                    <br>
                  </div>

                </td>
                <td class="pb-0">
                  <div class="dropdown no-wrap">
                    <ul class="dropdown-menu d-block m-0 p-0 border-0 position-relative"
                        v-if="$store.getters.tariff.can_edit">
                      <template v-for="(tone, key) in  tones(link)">
                        <li v-if="!tone.no_filter"
                            v-on:click="link.get_tone_display=tone.name;
                            link.tone=key;
                          updateLink(link);"
                            :class="`dropdown-item pb-0 pt-0 ${(tone.synonym.indexOf(link.tone)+1)?`active bg-${key}`:''}`">
                          {{ tone.name }}
                        </li>
                      </template>
                    </ul>
                    <span :style="$func.getClass(link, true)" v-else>{{ link.get_tone_display }}</span>
                  </div>
                </td>
              </tr>
            </template>
            </tbody>
          </table>
          <nav>
            <ul class="pagination justify-content-center fz-9" v-if="pager.length">
              <li :class="`page-item${p==page?' active':''}`" v-for="p in pager">
                <a v-if="typeof p === 'number'" class="page-link" v-on:click.prevent="page=p" href="">{{ p }}</a>
                <span class="page-link" v-else>{{ p }}</span>
              </li>
            </ul>
          </nav>


        </div>
      </div>
    </div>
    <modal modal_id="link_content">
      <template v-slot:header v-if="content">

      </template>
      <template v-slot:body>

        <div class="card">


          <div class="card-body pb-5" v-if="content">
            <article class="blog-post">
          <h2 class="blog-post-title" v-html="content.title"></h2>

          <p class="blog-post-meta">{{ content.date_create }}</p>


              <p v-html="content.description"></p>
              <hr>
              <div v-html="content.content"></div>
            </article>

          </div>
        </div>
      </template>
      <template v-slot:footer></template>
    </modal>
  </main>
</template>

<script>
import {ProjectApi} from '@/api/project'
import {LinkApi} from '@/api/links'
import CountryChoice from '@/components/CountryChoice';
import MultiSelect from '@/components/plugins/Select';

export default {
  name: 'LinksInfo',
  components: {CountryChoice, MultiSelect},

  data() {
    return {
      table: [],
      links: [],
      project: {},
      filter: {
        tone: [],
        keyword: '',
        uniq: true,
        state: null
      },
      active_country: {code: null, yandex: true, google: true},
      keywords: [],
      order_by: '',
      content: null,
      states: [10, 20, 50, 100],
      page: 1,
      show_all: false,
      size: 50,
      all_count: 0,
      q: ''
    }
  },
  computed: {
    pager() {
      let page_count = Math.ceil(this.all_count / this.size);
      let pages = Array.from({length: page_count}, (_, i) => i + 1);
      let array = [];
      if (this.page > 5) {
        array = array.concat(pages.slice(0, this.page - 3 > 3 ? 3 : this.page - 3));
        array.push('...')
      }
      array = array.concat(pages.slice(this.page - 4 > 0 ? this.page - 4 : 0, this.page + 3));
      if (this.page < (pages.length - 5)) {
        array.push('...');
        array = array.concat(pages.slice(pages.length - 3, pages.length));
      }
      return array;
    },
    query: function () {
      return this.$route.query
    },
    name: function () {
      let name = `${this.project.name}: `
      let data = this.$route.query;
      if (this.active_country.code) data.country_code = this.active_country.code;
      let query = {...data, ...this.filter}
      if (query.page_name) name += query.page_name;
      else {
        if (query.date) name += `${query.date} `
        name += `${query.uniq ? 'Уникальные ссылки ' : 'Неуникальные ссылки '}`
        if (query.state) name += `ТОП${query.state} `
        if (query.country_code) name += `${query.country_code} `
        if (query.engine) name += `${this.query.engine} `
        if (query.tone && query.tone !== 'null' && query.tone.indexOf('null') + 1) {
          let tones = query.tone;
          if (typeof tones === 'string') tones = [tones];
          tones.forEach(tone => name += `${this.$TONES[tone] ? this.$TONES[tone].name : ''} `)
        }
      }
      return name
    },
    filter_tones: function () {
      let data = Object.keys(this.$TONES).filter(x => !this.$TONES[x].no_filter).map(x => {
        return {id: x, name: this.$TONES[x].name}
      })
      data.push({id: 'error', name: 'Нет контента'})
      return data
    },
    tones: function () {
      let vm = this;
      return function (link) {
        return vm.$getTones(link.type_tone);
      };
    },

  },
  watch: {
    links(new_value) {
      this.table = new_value.slice()
      let order = this.order_by
      this.table = this.sorting_table_data(this.table, order)
    },

    order_by(new_value) {
      this.table = this.sorting_table_data(this.table, new_value)
    },

    page: {
      deep: true,
      handler() {
        this.getData();
      }
    }
  },
  mounted: function () {
    if (this.$route.query.tone) {
      let tone = this.$route.query.tone
      if (typeof tone === 'string') tone = [tone]
      this.filter.tone = tone;
    }
    if (this.$route.query.uniq) {
      this.filter.uniq = this.$route.query.uniq;
    }
    if (this.$route.query.state) {
      this.filter.state = this.$route.query.state;
    }


    let $this = this;
    ProjectApi.get(this.$route.params.id).then(resp => {
      this.project = resp;
      if ($this.query.country_code) $this.active_country = $this.project.countries.filter(x => x.code === $this.query.country_code)[0];
      else $this.active_country = $this.project.countries[0];
      this.getData();
      this.getKeywords()
    })
  },
  methods: {

    sorting_table_data(data, order_by) {
      if (order_by.indexOf('-') + 1) {
        order_by = order_by.slice(1);
        data.sort((a, b) => {
          return this.compareObjects(b, a, order_by);
        });
      } else {
        data.sort((a, b) => {
          return this.compareObjects(a, b, order_by);
        });
      }

      return data

    },
    createSearchParams: (params) => {
      const searchParams = new URLSearchParams();
      Object.entries(params).forEach(([key, values]) => {
        if (Array.isArray(values)) {
          values.forEach((value) => {
            searchParams.append(key, value);
          });
        } else {
          searchParams.append(key, values);
        }
      });
      return searchParams;
    },

    getKeywords() {
      ProjectApi.light_keywords(this.$route.params.id, this.active_country.code).then(x => this.keywords = x.results)
    },
    changeCountry: function (country) {
      if (this.active_country.code !== country.code) {
        this.active_country = country;
        this.getData();
        this.getKeywords();
      }
    },
    getContent: function (id) {
      let $this = this;
      let keywords = Object.values(this.keywords).map(x => x.name)
      LinkApi.get_content(id).then(response => {
        let content = response;
        keywords.forEach(key => {
          if (content.content) content.content = content.content.replaceAll(key, `<span class="bg-warning">${key}</span>`)
          if (content.description) content.description = content.description.replaceAll(key, `<span class="bg-warning">${key}</span>`)
          if (content.title) content.title = content.title.replaceAll(key, `<span class="bg-warning">${key}</span>`)
        })
        $this.content = content;
        $this.$modal("show", 'link_content');
      })
    },

    getData: function () {
      let $this = this, data = this.$route.query;
      if (this.active_country.code) data.country_code = this.active_country.code;
      let query = {...data, ...this.filter}
      query.page = $this.page;
      query.size = $this.size;
      query.project = $this.$route.params.id;
      query.q = $this.q;// eslint-disable-next-line
      LinkApi.list(this.createSearchParams(Object.fromEntries(Object.entries(query).filter(([_, v]) => Array.isArray(v) ? v.length : !!v)))).then(response => {
        $this.links = response.results;
        $this.all_count = response.count;
      })
    },
    compareObjects: function (object1, object2, key) {
      if (key) {
        const obj1 = object1[key] != null ? typeof object1[key] == 'string' ? object1[key].toUpperCase() : object1[key] : '';
        const obj2 = object2[key] != null ? typeof object2[key] == 'string' ? object2[key].toUpperCase() : object2[key] : '';
        if (obj1 < obj2) {
          return -1
        }
        if (obj1 > obj2) {
          return 1
        }
        return 0
      } else {
        if ((object1.date_create > object2.date_create) && (Object.keys(object1.keyword).length > 0)) {
          return -1
        }
        return 1

      }
    },
    changeOrder: function (name) {
      if (this.order_by === name) {
        if (name.indexOf('-') + 1) this.order_by = name.slice(1);
        else this.order_by = '-' + name
      } else this.order_by = name

    },
    getOrderClass: function (name) {
      let o_class = 'bi bi-caret';
      if (this.order_by.indexOf(name) + 1) {
        if (this.order_by.indexOf('-') + 1) o_class += '-up';
        else o_class += '-down';
        o_class += '-fill'
      } else o_class += '-down';
      return o_class;
    },
    updateLink: function (link) {
      let $this = this;
      LinkApi.update_link(link).then(() => {
        $this.$notify({
          group: 'alert',
          type: 'success ',
          text: 'Данные обновлены'
        });
      })
    }
  }
}

</script>

<style>
.date {
  height: 100px;
  margin: 20px 0;
}

.link {
  cursor: pointer;
}

td {
  max-width: 400px;
}

.table thead th {
  white-space: nowrap;
}

.keys {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.keys .key {
  /*width: 350px;*/
  white-space: normal;
}

.dropdown-item {
  padding-top: 0;
  padding-bottom: 0;
}

.loader.center {
  position: absolute;
  top: 0;
  background: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 100%;
  text-align: center;
}
</style>
